import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TopBarComponent from '../components/topBarComponent';
import SideBarComponent from '../components/sidebarComponent';
import style from './MainLayout.module.css';
import { setNavigateFunction } from '../services/nevigationService';
import { SelectChangeEvent, Typography } from '@mui/material';
import { getMyProfile } from '../services/apiService';
import { User } from '../types';
import { useClinic } from '../components/ClinicProvider';
import disconnected from '../assets/images/disc.webp';
import ImageWithSkeleton from '../components/customImageComponent';
import { usePatientContext } from '../components/patinetProvider';
import { usePatientContext as useSubPatientContext } from '../components/SubPatinetProvider';

const MainLayout: React.FC = () => {

  function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
  
    useEffect(() => {
      ref.current = value;
    }, [value]);
  
    return ref.current;
  }

  const navigate = useNavigate();

  const [user, setUser] = React.useState<User | null>(null);
  const [clinic, setClinic] = React.useState<string>('');
  const { setClinicId, ClinicId } = useClinic();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const previousClinic = usePrevious(ClinicId);
  const { removePatient } = usePatientContext();
  const { removeSubPatient } = useSubPatientContext();
  
  const handleChange = (event: SelectChangeEvent) => {
    setClinic(event.target.value);
  };

  // Fetch user profile when component mounts
  React.useEffect(() => {
    getMyProfile().then((response: User) => {
      setUser(response);
      localStorage.setItem('user', JSON.stringify(response));
    });
  }, []);

  // Set default clinic when the user data is loaded
  useEffect(() => {
    if (user?.clinic_department && user?.clinic_department.length > 0) {
      setClinic(user.clinic_department[0].clinic_id.toString()); // Set default clinic
    }
    setLoading(false); 
  }, [user]);


  useEffect(() => {
    setClinicId(clinic);
  }, [clinic, setClinicId]);
  
  // Set the navigate function in the service
  useEffect(() => {
    setNavigateFunction(navigate, location);
  }, [navigate, location]);

  useEffect(() => {
    if (ClinicId && previousClinic) {
      removeSubPatient();
      removePatient();
      const autoPaths = ['/admin/appointments', '/admin/registration/patient-list', '/clinical/patients'];
      const path = location.pathname;
      
      if (!autoPaths.includes(path)) {
        if (path.includes('clinical')) {
          navigate('/clinical/patients');
        } else if (path.includes('appointments')) {
          navigate('/admin/appointments');
        } else {
          navigate('/admin/registration/patient-list');
        }
      }
    }
    // eslint-disable-next-line
  }, [ClinicId]);

  if (loading) {
    return <div>Loading...</div>; 
  }
  return (
    <div className={style.main}>
      {user && <TopBarComponent handleChange={handleChange} clinic={clinic} user={user}/>}
      {clinic? <div className={style.content}>
        <div className={style.sidebar}>
          <SideBarComponent/>
        </div>
        <main className={style.main_page}>
          <div className={style.outletContent}>
            <Outlet context={clinic}/>  {/* This renders the child routes like Dashboard */}
          </div>
        </main>
      </div>: <div className={style.no_id}>
        <Typography variant='h6'>Sorry! Looks like you have not been assigned a clinic. Please <span>contact your Administrator</span>.</Typography>
        <ImageWithSkeleton src={disconnected} width={200} alt='no clinic'/>
      </div>}
    </div>
  );
};

export default MainLayout;