/* eslint-disable no-console */
import { NavigateOptions, Location } from 'react-router-dom';

let navigateFunction: (path: string, options?: NavigateOptions) => void;
let currentLocation: Location | null = null;

// Set the navigate function (from React Router's useNavigate)
export const setNavigateFunction = (
  navigate: (path: string, options?: NavigateOptions) => void,
  location: Location
) => {
  navigateFunction = navigate;
  currentLocation = location; // Save the current location state
};

// Navigate function with fallback to current location state
export const navigateTo = (path: string, options?: NavigateOptions) => {
  if (navigateFunction) {
    if (options?.state?.from !== '/login') {
      navigateFunction(path, options);
    } else if (currentLocation?.pathname) {
      console.log('Using current location state:', currentLocation);
      navigateFunction(path, {
        state: {
          from: currentLocation?.pathname
        },
      });
    } else {
      navigateFunction(path);
    }
  } else {
    console.error('Navigate function not set. Make sure to call setNavigateFunction in your app.');
  }
};