import { getRequest } from '../../../services/httpService';


export function getstimulationChartUrl(Id: string): string {
  return `/stimulation-chart/${Id}/`; 
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getstimulationChart(Id: string): Promise<any> {
  return getRequest(getstimulationChartUrl(Id), { responseType: 'json' });
}
