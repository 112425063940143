/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { Modal, Box, Typography, TextField, Button, FormHelperText, Autocomplete } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './Calendar.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { bookOutOfOffice, getMyProfileBasic, getUsersByClinicOnly } from '../../services/apiService';
import { useLoader } from '../loaderProvider/loader';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { splitDateRangeIntoDailyGroups } from './Calendar.helper';
import { convertDateToISOWithOffset, formatDateToDDMMYYYY } from '../../services/time';
import { UserBasic } from '../../types';
import { useAlert } from '../alertProvider';
import { AxiosError } from 'axios';
import { useClinic } from '../ClinicProvider';

interface OutOfOfficeFormProps {
  userId?: number;
  open: boolean;
  handleClose: () => void;
}

const OutOfOfficeForm: FC<OutOfOfficeFormProps> = ({ open, handleClose }) => {
  const [user, setUser] = useState<UserBasic | undefined>();
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [users, setUsers] = React.useState<UserBasic[] | undefined>(undefined);
  const { ClinicId } = useClinic();

  useEffect(() => {
    showLoader();
    getUsersByClinicOnly(ClinicId).then((userRes) => setUsers(userRes.objects));
    getMyProfileBasic()
      .then((response: UserBasic) => setUser(response))
      .catch((error) => console.error(error))
      .finally(() => hideLoader());
  }, [hideLoader, showLoader, ClinicId]);

  const handleModelClose = () => {
    setUser(undefined);
    setUsers(undefined);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      start_datetime: '',
      end_datetime: '',
      reason: '',
    },
    validationSchema: Yup.object({
      start_datetime: Yup.string()
        .required('Start date and time is required')
        .test('is-future', 'Start date and time must be in the future', (value) =>
          value ? new Date(value) > new Date() : false
        ),
      end_datetime: Yup.string()
        .required('End date and time is required')
        .test('is-after-start', 'End date and time must be after the start date and time', function (value) {
          const { start_datetime } = this.parent;
          return value ? new Date(value) > new Date(start_datetime) : false;
        }),
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: async (values) => {
      if (user) {
        const dates = splitDateRangeIntoDailyGroups(new Date(values.start_datetime), new Date(values.end_datetime));
        dates.forEach((date) => {
          const request = {
            user: user.id,
            end_datetime: convertDateToISOWithOffset(date.end),
            start_datetime: convertDateToISOWithOffset(date.start),
            remark: values.reason,
            type: 'OUT_OF_OFFICE',
            appointment_date: formatDateToDDMMYYYY(date.start),
            room: null,
            table: null,
          };

          bookOutOfOffice(request).then(() => {
            addAlert('Out of office marked successfully.', 'success');
          }).catch((error: AxiosError) => addAlert(error.response?.statusText || 'Error occurred', 'error')).finally(() => {
            hideLoader();
            handleModelClose();
          });
        });
      }
    },
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box className="cal-ooo-modal">
        <div className="cal-ooo-heading">
          <Autocomplete
            size="small"
            options={users?.map((user) => ({ label: `${user.first_name} ${user.last_name}`, id: user.id })) || []}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Choose another User" />}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              const selectedUser = users?.find((user) => user.id === newValue?.id);
              if (selectedUser) setUser(selectedUser);
            }}
          />
          <div>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h3"
              fontFamily={'nunito'}
              fontWeight={700}
              color="#E17E61"
            >
              <WorkOffIcon /> Mark Out of Office
            </Typography>
          </div>
        </div>
        <div className="cal-ooo-form">
          <Typography id="modal-modal-title" variant="h4" component="h2" fontFamily={'nunito'} color="#747373">
            <label>{`${user?.first_name} ${user?.last_name}`}</label>
          </Typography>
          <div className="cal-ooo-secinfo">
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={700} color="#747373">
              <label>{user?.email}</label>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={200} color="#747373">
              <label>{user?.username}</label>
            </Typography>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Start Date & Time"
              type="datetime-local"
              value={formik.values.start_datetime}
              onChange={(e) => formik.setFieldValue('start_datetime', e.target.value)}
              fullWidth
              margin="normal"
              error={Boolean(formik.errors.start_datetime && formik.touched.start_datetime)}
              helperText={formik.touched.start_datetime && formik.errors.start_datetime}
              InputLabelProps={{
                shrink: true, // Ensures the label stays above the input field
              }}
            />
            <TextField
              label="End Date & Time"
              type="datetime-local"
              value={formik.values.end_datetime}
              onChange={(e) => formik.setFieldValue('end_datetime', e.target.value)}
              fullWidth
              margin="normal"
              error={Boolean(formik.errors.end_datetime && formik.touched.end_datetime)}
              helperText={formik.touched.end_datetime && formik.errors.end_datetime}
              InputLabelProps={{
                shrink: true, // Ensures the label stays above the input field
              }}
            />
            <TextField
              label="Reason"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={3}
              error={Boolean(formik.errors.reason && formik.touched.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={handleClose} color="secondary" variant="outlined" style={{ marginRight: '8px' }}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default OutOfOfficeForm;