/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Autocomplete, Box, Button, Grid, TextField, Typography, } from '@mui/material';
import { createCycle,  cycleCreatorsearch,  CycleData,  cycleFields, getProcedure, initialValues, medicationProtocol, TouchedFields, validationSchema } from './CreateNewCycleHelper';
import styles from './CycleOverViewList.module.css';
import { RequiredLabel } from '../../utils/commonUtils';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { ConfirmationModal } from '../confirmationModal';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import { usePatientContext } from '../SubPatinetProvider';
interface CreateNewCycleProps {
  onClose: () => void;
}

const CreateNewCycle: React.FC<CreateNewCycleProps> = ({ onClose }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  type ValidateForm = () => Promise<Record<string, string>>;
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [CycleCreatorID, setCycleCreatorID] = useState<string>('');
  const handleCreateCycle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, validateForm: ValidateForm, setTouched: (touched: TouchedFields) => void) => {
    validateForm().then((errors: Record<string, string>) => {
      const isValid = Object.keys(errors).length === 0;
      const missingFields: string[] = [];
      Object.keys(cycleFields).forEach((field) => {
        if (cycleFields[field as keyof typeof cycleFields] && errors[field]) {
          missingFields.push(field);
        }
      });
      if (isValid) {
        setOpenConfirmDialog(true);
      } else {
        setTouched(cycleFields);
      }
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [CycleCoordinator, setCycleCoordinator] = useState<any[]>([]);
  // const [cycleCreator, setCycleCreator] = useState<any[]>([]);
  useEffect(() => {
    cycleCreatorsearch('DOCTOR') 
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        showLoader();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedCycleCreators = response.objects.map((creator: any) => ({
          ...creator,
          fullName: `${creator.first_name} ${creator.last_name}`  
        }));
        setCycleCoordinator(formattedCycleCreators);
      })
      .catch((err) => {
        console.error(err); 
      })
      .finally(() => {
        hideLoader();
      });
  }, [hideLoader,showLoader]);

  
   

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [CycleProvider, setCycleProvider] = useState<any[]>([]);
  useEffect(() => {
    cycleCreatorsearch('NURSE') 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        showLoader();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedCycleCreators = response.objects.map((creator: any) => ({
          ...creator,
          fullName: `${creator.first_name} ${creator.last_name}`  
        }));
        // setCycleCoordinator(formattedCycleCreators);
        setCycleProvider(formattedCycleCreators);
      })
      .catch((err) => {
        console.error(err); 
      })
      .finally(() => {
        hideLoader();
      });
  }, [hideLoader,showLoader]);

    
  
  const handleSubmit = (values: CycleData) => {
    const cycleDataWithStatus = { ...values, status: 'ACTIVE', patient: patientId, cycle_creator: CycleCreatorID };
    showLoader();
    
    createCycle(cycleDataWithStatus)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {
        hideLoader();
        addAlert('Cycle created successfully', 'success');
        onClose();
      })
      .catch((error) => {
        hideLoader();
        if (error?.response?.data?.errors && Array.isArray(error.response.data.errors)) {
          const errorMessage = error.response.data.errors.map((err: { detail: string }) => err.detail).join(', ');
          addAlert(errorMessage, 'error');
        } else {
          console.error('Error creating cycle:', error);
          addAlert('Error creating cycle', 'error');
        }
      });
  };
  
  


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [procedures, setProcedures] = useState<any[]>([]); 

  useEffect(() => {
    getProcedure()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        setProcedures(data.objects); 
      })
      .catch((error) => {
        console.error('Error fetching procedure:', error); 
      });
  }, []);


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSubmit={(values) => {}}
    >
      {({ handleChange, handleBlur, values, errors, touched, validateForm, setTouched, }) => (
        <Form>

          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1rem">
            <Typography variant="h6" className={styles.CycleList}>
              Create New Cycle
            </Typography>
            <More style={{ cursor: 'pointer' }} onClick={onClose} />


          </Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4}>

              <Autocomplete
                id="procedure"
                disableClearable
                options={procedures} 
                getOptionLabel={(option) => option.procedures || ''} 
                value={procedures.find((p) => p.id === values.procedure) || null}
                onChange={(_, value) => {
                  handleChange({ target: { name: 'procedure', value: value?.id || '' } });
                  const selectedProcedure = procedures.find((p) => p.id === value?.id);
                  if (selectedProcedure) {
                    handleChange({ target: { name: 'indication', value: selectedProcedure.indication || '' } });
                    const doctorName = selectedProcedure.doctor_name || '';
                    handleChange({ target: { name: 'doctor_name', value: doctorName } });
                    const doctorId = selectedProcedure.doctor || '';
                    setCycleCreatorID(doctorId);
                    handleChange({ target: { name: 'cycle_creator', value: doctorName || '' } });
                  
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cycle Name"
                    value={
                      values.procedure
                        ? procedures.find((p) => p.id === values.procedure)?.procedures || '' 
                        : ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.procedure && !!errors.procedure} 
                    helperText={errors.procedure} 
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        type: 'search',
                      },
                    }}
                  />
                )}
              />


            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="indication"
                label="Indication"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.indication || ''}
                disabled  
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:  '#f5f5f5' ,
                  },
                }}
              />

            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="medication_protocol"
                options={medicationProtocol.map((option) => option.value)}
                value={values.medication_protocol || ''}
                onChange={(_, value) => handleChange({ target: { name: 'medication_protocol', value } })}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={RequiredLabel('Medication Protocol', true)}
                    name="medication_protocol"
                    error={touched.medication_protocol && !!errors.medication_protocol}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#0C7DED',
                        },
                      },
                    }}
                  />
                )}
              />

            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="center" className="mt-2">
            <Grid item xs={12} sm={4}>

              <TextField
                name="cycle_creator"
                label="Cycle Creator"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cycle_creator || ''}  
                disabled  
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor:  '#f5f5f5' ,
                  },
                }}
              />


              {/* <Autocomplete
                id="cycle_creator"
                disableClearable
                options={cycleCreator}
                getOptionLabel={(option) => option.fullName}
                value={cycleCreator.find(option => option.id === values.cycle_creator) || null}
                onChange={(_, value) => {
                  // Set the cycle creator id and doctor name in the form
                  handleChange({ target: { name: 'cycle_creator', value: value?.id || '' } });
    
                  // Optionally, set additional data if needed
                  if (value) {
                    handleChange({ target: { name: 'doctor_name', value: value.fullName || '' } });
                    handleChange({ target: { name: 'doctor', value: value.id || '' } }); // Set the doctor id
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cycle Creator"
                    value={values.cycle_creator ? cycleCreator.find(option => option.id === values.cycle_creator)?.fullName : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        type: 'search',
                      },
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                    error={touched.cycle_creator && !!errors.cycle_creator}
                  />
                )}
              /> */}


            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="cycle_coordinator"
                options={CycleCoordinator}  
                getOptionLabel={(option) => option.fullName || ''} 
                value={CycleCoordinator.find(option => option.id === values.cycle_coordinator) || null}  
                onChange={(_, value) => {
                  handleChange({ target: { name: 'cycle_coordinator', value: value?.id || '' } });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cycle Co-ordinator" 
                    value={values.cycle_coordinator ? CycleCoordinator.find(option => option.id === values.cycle_coordinator)?.fullName : ''} 
                    onChange={handleChange}
                    onBlur={handleBlur} 
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        type: 'search', 
                      },
                    }}
                    error={touched.cycle_coordinator && !!errors.cycle_coordinator} 
                  />
                )}
              />
            </Grid>



            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="provider_name"
                disableClearable
                options={CycleProvider} 
                getOptionLabel={(option) => option.fullName || ''}  
                value={CycleProvider.find(option => option.id === values.cycle_provider) || null} 
                onChange={(_, value) => handleChange({ target: { name: 'cycle_provider', value: value?.id || '' } })} 
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cycle Provider"
                    value={values.cycle_provider ? CycleProvider.find(option => option.id === values.cycle_provider)?.fullName : ''} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        type: 'search', 
                      },
                    }}
                    error={touched.cycle_provider && !!errors.cycle_provider} 
                  />
                )}
              />

            </Grid>
          </Grid>


          <Grid container spacing={2} justifyContent="center" className="mt-2">
            <Grid item xs={12} sm={4}>
              <TextField
                name="cycle_start_date"
                label={RequiredLabel('Cycle Start Date', true)}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                type='date'
                InputLabelProps={{ shrink: true }}
                value={values.cycle_start_date || ''}
                error={touched.cycle_start_date && !!errors.cycle_start_date}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0C7DED',
                    },
                  },
                }}
                inputProps={{
                  max: new Date().toISOString().split('T')[0], 
                }}
              />

            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="cycle_end_date"
                label="Cycle End Date"
                fullWidth
                type='date'
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cycle_end_date || ''}
                inputProps={{
                  min: values.cycle_start_date ? new Date(new Date(values.cycle_start_date).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0C7DED',
                    },
                  },
                }}
              />

            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="lmp_date"
                label={RequiredLabel('LMP Date', true)}
                fullWidth
                type='date'
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lmp_date || ''}
                error={touched.lmp_date && !!errors.lmp_date}
                inputProps={{
                  max: values.cycle_start_date ? new Date(values.cycle_start_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0], 
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0C7DED',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="start" className="mt-2">
            <Grid item xs={12} sm={4}>
              <TextField
                name="cycle_warning"
                label="Cycle Warning"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cycle_warning || ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0C7DED',
                    },
                  },
                }}
              />

            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="remarks"
                label="Remarks"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks || ''}
                multiline
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#0C7DED',
                    },
                  },
                }}

              />


            </Grid>
          </Grid>

          <hr className="mt-4" />


          <div>
            <Box display="flex" justifyContent="flex-end" alignItems="center" padding="1rem">
              <Button
                variant="contained"
                color="primary"
                className={styles.AddNewCycle}
                onClick={(e) => handleCreateCycle(e, validateForm, setTouched)}
              >
                <span className={styles.AddNewCycleText} >Create New Cycle</span>
              </Button>
            </Box>
          </div>
          <ConfirmationModal
            open={openConfirmDialog}
            handleClose={() => setOpenConfirmDialog(false)}
            onConfirm={() => {
              setOpenConfirmDialog(false);
              handleSubmit(values);
            }}
            title="Create New Cycle"
            contentMessage="Are you sure you want to create a new cycle?"
            type={'save'}
          />
        </Form>
      )}


    </Formik>
  );
};

export default CreateNewCycle;
