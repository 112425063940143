/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import HeaderProfile from '../../../components/header_Profile/header_Profile.index';
import Investigation from './Investigation/investigation.index';
import styles from './investigation_diagnosis.module.css';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControlLabel,
  TextField,
  ThemeProvider,
  Button,
} from '@mui/material';
import Diagnosis from './Diagnosis/diagnosis.index';

interface PatientData {
  id: number;
  gender: 'MALE' | 'FEMALE';
  age: number;
  nameTitle: string;
  firstName: string;
  lastName: string;
  cycleNo: string;
  mrNo: string;
  updatedDatetime: Date;
  LastModified: string;
  visitID: string;
  genderId: string;
  visitedDoctorID: string;
  therapyId: string;
}


export default function InvestigationDiagno() {
  const [selectedPatient, setselectedPatient] = useState<PatientData | null>(null);;
  const [activeTab, setActiveTab] = useState('Test');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDatafromHeader = (data: any) => {
    setselectedPatient(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div >
        <div>
          <HeaderProfile onData={handleDatafromHeader} />
        </div>

        <Grid sx={{ my: 2, px: 3 }}>
          <div>
            <div className={styles.tabs}>
              <button
                className={styles.tab}
                style={{
                  backgroundColor: activeTab === 'Test' ? 'white' : 'transparent',
                  color: activeTab === 'Test' ? '#f65e39' : 'black',
                  boxShadow: activeTab === 'Test' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '',

                }}
                onClick={() => setActiveTab('Test')}
              >
      Test
              </button>
              <button
                className={styles.tab}
                style={{
                  backgroundColor: activeTab === 'Diagnosis' ? 'white' : 'transparent',
                  color: activeTab === 'Diagnosis' ? '#f65e39' : 'black',
                  boxShadow: activeTab === 'Diagnosis' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '',
                }}
                onClick={() => setActiveTab('Diagnosis')}
              >
      Diagnosis
              </button>
            </div>
          </div>
          <div>
            {selectedPatient?.id && activeTab === 'Test' && (
              <Investigation />
            )}
            {selectedPatient?.id && activeTab === 'Diagnosis' && (
              <Diagnosis />
            )}
          </div>
        </Grid>

      </div>
    </ThemeProvider>
  );
}