import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

// Define the context type
interface ClinicContextType {
  ClinicId: string;
  setClinicId: (id: string) => void;
}

// Create the ClinicContext
const ClinicContext = createContext<ClinicContextType | undefined>(undefined);

// Custom hook to use the ClinicContext
export const useClinic = (): ClinicContextType => {
  const context = useContext(ClinicContext);
  if (!context) {
    throw new Error('useClinic must be used within a ClinicProvider');
  }
  return context;
};

// ClinicProvider component to wrap the app
export const ClinicProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ClinicId, setClinicIdState] = useState<string>(''); // Default Clinic ID, can be an empty string or a default value

  // Memoize setClinicId to avoid re-creation on each render
  const setClinicId = useCallback((id: string) => {
    setClinicIdState(id);
  }, []);

  return (
    <ClinicContext.Provider value={{ ClinicId, setClinicId }}>
      {children}
    </ClinicContext.Provider>
  );
};