import { Box, Modal } from '@mui/material';
import React from 'react';
import FollicularScan from './Follicular_Scan';

interface FolliculerProps {
  hormoneModalOpen: boolean;
  handleHormoneModalClose: () => void;
  date: Date,
  lmp?: Date,
  stim: number
}

const FolliculerModal: React.FC<FolliculerProps> = ({ handleHormoneModalClose, hormoneModalOpen, date, stim, lmp }) => {
  return (
    <div>
      <Modal
        open={hormoneModalOpen}
        onClose={handleHormoneModalClose}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <FollicularScan handleHormoneModalClose={handleHormoneModalClose}  date={date} stim={stim} lmp={lmp}/>
        </Box>
      </Modal>
    </div>
  );
};

export default FolliculerModal;