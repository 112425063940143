// src/ErrorBoundary.tsx
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component, ReactNode, useCallback } from 'react';
import AlertComponent from './components/alertComponent';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, errorMessage: error.message }; // Store the error message
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // <AlertComponent error={error.message}/>;
    // eslint-disable-next-line no-console
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }
  handleCloseAlert = () => {
    this.setState({ hasError: false, errorMessage: null }); // Reset the error state
  };
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Oops! Something went wrong.</h1>
          {this.state.errorMessage && (
            <AlertComponent error={this.state.errorMessage} severity="error"  onClose={this.handleCloseAlert} />
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;