import React from 'react';
import styles from './OocyteRetrieval.module.css';
import { ReactComponent as EmbryoTransferProcedure } from '../../../assets/SVG/Embryo Transfer Procedure.svg';
import Grid from '@mui/material/Grid'; 

const OocyteRetrieval: React.FC = () => {
  const retrievalData = {
    retrievalDate: '2024-12-05',
    retrievalTime: '10:30 AM',
    physician: 'Dr. Smith',
    embryologist: 'Emily Johnson',
    nurse: 'Nurse Anna',
    expectedFollicles: '12',
    oocytesRetrieved: '10',
    levelOfDifficulty: 'Medium',
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4} sm={12} md={6}>
          <div className={styles.embryoProcedure}>
            <EmbryoTransferProcedure />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Retrieval Date:</strong>
                <p className={styles.customTextStyle}>{retrievalData.retrievalDate}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Retrieval Time:</strong>
                <p className={styles.customTextStyle}>{retrievalData.retrievalTime}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Physician:</strong>
                <p className={styles.customTextStyle}>{retrievalData.physician}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Embryologist:</strong>
                <p className={styles.customTextStyle}>{retrievalData.embryologist}</p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Nurse:</strong>
                <p className={styles.customTextStyle}>{retrievalData.nurse}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Expected Follicles:</strong>
                <p className={styles.customTextStyle}>{retrievalData.expectedFollicles}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Oocytes Retrieved:</strong>
                <p className={styles.customTextStyle}>{retrievalData.oocytesRetrieved}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Level of Difficulty:</strong>
                <p className={styles.customTextStyle}>{retrievalData.levelOfDifficulty}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OocyteRetrieval;
