/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styles from './Follicular_Scan.module.css';
import { ThemeProvider } from '@emotion/react';
import { Button, Grid, MenuItem, TextField, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { theme } from '../../theme';
import { RequiredLabel } from '../../utils/commonUtils';
import { formatDateToLocalYYYYMMDD } from '../../services/time';

type FollicularDetails = {
  date: string;
  time: string;
  lmpDate: string;
  cycleDay: string;
  endometriumThickness?: string;
  ri?: string;
  psv?: string;
  endometriumMorphology?: string;
  cystLeftOvary?: string;
  cystRightOvary?: string;
  remark?: string;
};

interface FolliculerProps {
  handleHormoneModalClose: () => void;
  date: Date,
  stim: number,
  lmp?: Date
}

const  FollicularScan: React.FC<FolliculerProps> = ({ handleHormoneModalClose, date, stim, lmp }) => {
  const { state } = useLocation();
  const { record } = state || {};

  const [rows, setRows] = useState<number[]>([]);
  const [folliclesData, setFolliclesData] = useState<{
    [key: number]: { leftSize: string; rightSize: string; leftFollicles: string; rightFollicles: string };
  }>({});
  const [follicularDetails, setFollicularDetails] = useState<FollicularDetails>({
    date: formatDateToLocalYYYYMMDD(date),
    time: record?.time || '',
    lmpDate: lmp? formatDateToLocalYYYYMMDD(lmp): formatDateToLocalYYYYMMDD(new Date()),
    cycleDay: record?.cycle_day || '',
    endometriumThickness: record?.endometrium_thickness || '',
    ri: record?.ri || '',
    psv: record?.psv || '',
    endometriumMorphology: record?.endometrium_morphology || '',
    cystLeftOvary: record?.cyst_left_ovary || '',
    cystRightOvary: record?.cyst_right_ovary || '',
    remark: record?.remark || '',
  });

  
  type Patient = {
    id: number;
    name: string;
    stimulationChartId: number;
  };
  const [selectedPatient, setSelectedPatient] = useState<Patient>({
    id: record?.id || 0,
    name: '',
    stimulationChartId: 9,
  });

  useEffect(() => {
    const savedRows = localStorage.getItem('follicularRows');
    const savedFolliclesData = localStorage.getItem('folliclesData');
    if (savedRows) {
      setRows(JSON.parse(savedRows));
    } else {
      setRows([1, 2, 3, 4, 5]); // Default 5 rows if not saved
    }
    if (savedFolliclesData) {
      setFolliclesData(JSON.parse(savedFolliclesData));
    }
  }, []);

  const handleDatafromHeader = (data: any) => {
    setSelectedPatient(data);
  };

  const addMoreRows = () => {
    const newRows = [...rows, ...Array.from({ length: 5 }, (_, index) => rows.length + index + 1)];
    setRows(newRows);
  };

  const deleteRow = (rowId: number) => {
    const updatedRows = rows.filter((row) => row !== rowId);
    setRows(updatedRows);

    const updatedFolliclesData = { ...folliclesData };
    delete updatedFolliclesData[rowId];
    setFolliclesData(updatedFolliclesData);
  };

  const handleInputChange = (
    rowId: number,
    field: 'leftSize' | 'rightSize' | 'leftFollicles' | 'rightFollicles',
    value: string
  ) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    setFolliclesData((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: numericValue,
      },
    }));
  };

  const validateForm = () => {
    const errors: string[] = [];

    if (!follicularDetails.date) errors.push('Date is required.');
    if (!follicularDetails.time) errors.push('Time is required.');
    if (!follicularDetails.lmpDate) errors.push('LMP Date is required.');
    if (!follicularDetails.cycleDay) errors.push('Cycle Day is required.');

    // Validate rows' data for left and right follicles
    rows.forEach((rowId) => {
      const follicleData = folliclesData[rowId];
      if (!follicleData?.leftSize || !follicleData?.rightSize) {
        errors.push(`Follicular Size for row ${rowId} is required.`);
      }
    });

    if (errors.length > 0) {
      alert(`Please correct the following errors:\n- ${errors.join('\n- ')}`);
      return false;
    }

    return true;
  };

  const submitFollicularData = async () => {
    if (!validateForm()) {
      return;
    }

    const requestBody = {
      stimulation_chart: stim,
      endometrium_thickness: follicularDetails.endometriumThickness || '0',
      date: follicularDetails.date,
      time: follicularDetails.time,
      ri: follicularDetails.ri || '-',
      psv: follicularDetails.psv?.replace(/[^0-9.]/g, '') || '-',
      endometrium_morphology: follicularDetails.endometriumMorphology || '',
      cyst_left_ovary: follicularDetails.cystLeftOvary || '-',
      cyst_right_ovary: follicularDetails.cystRightOvary || '-',
      remark: follicularDetails.remark || '',
      details: rows.map((rowId) => ({
        left_follicle_size: folliclesData[rowId]?.leftSize || '-',
        left_no_of_follicles: folliclesData[rowId]?.leftFollicles || '-',
        right_follicle_size: folliclesData[rowId]?.rightSize || '-',
        right_no_of_follicles: folliclesData[rowId]?.rightFollicles || '-',
      })),
    };

    try {
      const response = await fetch('https://stage-api.vidaisolutions.com/api/follicular-study-results/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        alert('Data submitted successfully!');
        console.log(data);
      } else {
        const error = await response.json();
        alert(`Error: ${error.errors.map((e: { detail: any }) => e.detail).join(', ')}`);
        console.error(error);
      }
    } catch (err) {
      alert('An error occurred while submitting data.');
      console.error(err);
    }
  };

  return (
    <div>
      <div className="mt-4">
        <div className={styles.container}>
          <span className={styles.headerText} style={{ display: 'block', marginBottom: '20px' }}>
              Follicular Scan
          </span>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  label="Stimulation Chart ID"
                  name="stimulationChartId"
                  type="number"
                  value={stim}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  label={RequiredLabel('Date', true)}
                  name="date"
                  type="date"
                  value={follicularDetails.date || ''}
                  onChange={(e) =>
                    setFollicularDetails((prev) => ({ ...prev, date: e.target.value }))
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  size="small"
                  label={RequiredLabel('Time', true)}
                  name="time"
                  type="time"
                  value={follicularDetails.time || ''}
                  onChange={(e) =>
                    setFollicularDetails((prev) => ({ ...prev, time: e.target.value }))
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  size="small"
                  label={RequiredLabel('LMP Date', true)}
                  name="lmpDate"
                  type="date"
                  value={follicularDetails.lmpDate || ''}
                  onChange={(e) =>
                    setFollicularDetails((prev) => ({ ...prev, lmpDate: e.target.value }))
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  size="small"
                  label={RequiredLabel('Cycle Day', true)}
                  name="cycleDay"
                  type="number"
                  value={follicularDetails.cycleDay || ''}
                  onChange={(e) =>
                    setFollicularDetails((prev) => ({
                      ...prev,
                      cycleDay: e.target.value.replace(/[^0-9]/g, ''),
                    }))
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </form>

          <hr />
          {/* Follicular Scan Table */}
          <Grid container className={styles.tableEnteries}>
            <Grid item xs={6}>
              <Typography variant="h4" className={styles.tableHeader}>
                  Left Ovary
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" className={styles.tableHeader}>
                  Right Ovary
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={styles.tableEnteries}>
            <Grid item xs={2}>
              <Typography variant="h6">Follicular Size (mm)</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">No. of Follicles </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Follicular Size (mm)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">No. of Follicles</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6">Action</Typography>
            </Grid>
            {rows.map((rowId) => (
              <Grid container spacing={2} key={rowId} className={styles.inputRow}>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    placeholder="Left Size"
                    value={folliclesData[rowId]?.leftSize || ''}
                    onChange={(e) => handleInputChange(rowId, 'leftSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    placeholder="Left Follicles"
                    value={folliclesData[rowId]?.leftFollicles || ''}
                    onChange={(e) => handleInputChange(rowId, 'leftFollicles', e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    placeholder="Right Size"
                    value={folliclesData[rowId]?.rightSize || ''}
                    onChange={(e) => handleInputChange(rowId, 'rightSize', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    placeholder="Right Follicles"
                    value={folliclesData[rowId]?.rightFollicles || ''}
                    onChange={(e) => handleInputChange(rowId, 'rightFollicles', e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="error"
                    onClick={() => deleteRow(rowId)}
                    disabled={rows.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <div className={styles.addMoreRows}>
            <Button onClick={addMoreRows} style={{ textTransform: 'none' }}>
                Add 5 Rows
            </Button>
          </div>

          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label="Endometrium Thickness (mm)"
                value={follicularDetails.endometriumThickness || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, endometriumThickness: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label="RI"
                value={follicularDetails.ri || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, ri: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                label="PSV"
                value={follicularDetails.psv || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, psv: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                size="small"
                select
                label="Endometrium Morphology"
                value={follicularDetails.endometriumMorphology || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, endometriumMorphology: e.target.value }))
                }
              >
                <MenuItem value="HYPERECHOEIC">HYPERECHOEIC</MenuItem>
                <MenuItem value="Type2">Type 2</MenuItem>
                <MenuItem value="Type3">Type 3</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth size="small"
                label="Cyst in Left Ovary"
                value={follicularDetails.cystLeftOvary || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, cystLeftOvary: e.target.value }))
                } />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth size="small"
                label="Cyst in Right Ovary"
                value={follicularDetails.cystRightOvary || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, cystRightOvary: e.target.value }))
                } />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth size="small"
                label="Remark" value={follicularDetails.remark || ''}
                onChange={(e) =>
                  setFollicularDetails((prev) => ({ ...prev, remark: e.target.value }))
                } />
            </Grid>
          </Grid>

          <div className={styles.customButtonDiv} style={{ marginTop: '20px' }}>
            <Button variant="contained" className={styles.cancelButton} onClick={handleHormoneModalClose}>
                Cancel
            </Button>
            <span className={styles.saveBtnsDiv}>
              <Button variant="contained" className={styles.saveFinalButton}>
                  Save & Finalize
              </Button>
              <Button
                variant="contained"
                className={styles.customButton}
                onClick={submitFollicularData}
              >
                  Save
              </Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollicularScan;