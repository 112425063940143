import React from 'react';
import styles from './Fertilization.module.css';
import { ReactComponent as EmbryoTransferProcedure } from '../../../assets/SVG/Embryo Transfer Procedure.svg';
import Grid from '@mui/material/Grid';

const Fertilization: React.FC = () => {
  return (
    <div className={styles.container}>
      <Grid container spacing={3}>
        {/* First column with images */}
        <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <EmbryoTransferProcedure style={{ width: '12.5rem', height: '12.5rem' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <EmbryoTransferProcedure style={{ width: '12.5rem', height: '12.5rem' }} />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <EmbryoTransferProcedure style={{ width: '12.5rem', height: '12.5rem' }} />
            </Grid>
          </Grid>
        </Grid>

        {/* Second column with data */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Total oocyte Retrieved</strong>
                <p className={styles.customTextStyle}>4</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Oocyte Source</strong>
                <p className={styles.customTextStyle}>Self</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Sperm Source</strong>
                <p className={styles.customTextStyle}>Donor(#123456)</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Fertilization Technique</strong>
                <p className={styles.customTextStyle}><span>IVF:1</span>&nbsp;&nbsp; <span>ICSI:1</span></p>
              </div>
            </Grid>

          </Grid>
          <hr className='mt-4' />

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={3} md={6} lg={3} className='mt-4'>
                <div className={styles.containerd}>
                  <div className={styles.circle}>01</div>
                  <div className={styles.text}>M II</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} className='mt-4'>
                <div className={styles.containerd}>
                  <div className={styles.circle}>01</div>
                  <div className={styles.text}>M I</div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} className='mt-4'>
                <div className={styles.containerd}>
                  <div className={styles.circle}>01</div>
                  <div className={styles.text}>GV</div>
                </div>
              </Grid>


              <Grid item xs={12} sm={12} md={6} lg={3} className='mt-4'>
                <div className={styles.containerd}>
                  <div className={styles.circle}>01</div>
                  <div className={styles.text}>GV</div>
                </div>
              </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.box}></div>
                    <span className={styles.headerTextStyle}>
                      Freezed <span className={styles.customTextStyle}>08</span>
                    </span>
                  </div>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.boxCulture}></div>
                    <span className={styles.headerTextStyle}>
                      Culture <span className={styles.customTextStyle}>08</span>
                    </span>
                  </div>
                </Grid>




                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.boxDiscard}></div>
                    <span className={styles.headerTextStyle}>
                      Discard <span className={styles.customTextStyle}>08</span>
                    </span>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Fertilization;
