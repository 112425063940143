import React from 'react';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { Autocomplete, Box, TextField, Button, Typography, } from '@mui/material';
import { RequiredLabel } from '../../utils/commonUtils';
import { initialValues, validationSchema, reasonOptions, reasonTerminateOptions, putTerminateCycle, TerminateCycleData } from './TerminateCycleConfirmationHelper';
import { ConfirmationModal } from '../confirmationModal';
import { Formik, Form } from 'formik';
import styles from './CycleOverViewList.module.css';
import { theme } from '../../components/Medical_History_Forms/themeprovider';
import { ThemeProvider } from '@emotion/react';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';


interface TerminateCycleConfirmationCycleProps {
  onClose: () => void;
  cycleId: string | null;
}

const TerminateCycleConfirmation: React.FC<TerminateCycleConfirmationCycleProps> = ({ onClose ,cycleId }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const { showLoader, hideLoader } = useLoader(); 
  const { addAlert } = useAlert(); 
  const [formValues, setFormValues] = React.useState<TerminateCycleData>(initialValues);
  // const { patientId } = usePatientContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpenModal = async (values: any, validateForm: () => Promise<Record<string, string>>) => {
    const validationErrors = await validateForm();
    const isValid = Object.keys(validationErrors).length === 0;

    if (isValid) {
      setFormValues(values);  
      setOpenConfirmDialog(true);
    }
  };

  const handleSubmit = () => {
    showLoader();
    const numericCycleId = cycleId !== null ? Number(cycleId) : 0;
    const payload = {
      ...formValues,
      id: numericCycleId, 
      status:'TERMINATED'
    };
    putTerminateCycle(numericCycleId, payload)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {
        setOpenConfirmDialog(false);  
        addAlert('Terminate cycle successfully', 'success');
        onClose();  
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error submitting data:', error);
        addAlert('An error occurred while terminating the cycle.', 'error');
      })
      .finally(() => {
        hideLoader();
      });
  };
  

  return (
    <ThemeProvider theme={{ theme }}>
      <div className={'mt-4 p-0'}>
        <div className={'d-flex justify-content-between px-4'}>
          <div className={'d-flex justify-content-between'}>
            <div className={`${styles.textspan}`}>
              <span className={styles.titleText}>
                <Typography variant="h6">Terminate Cycle</Typography>
              </span>
              <span className={styles.spantext} style={{ marginTop: '1rem' }}>
                Are you sure you want to terminate the cycle?
              </span>
            </div>
          </div>
          <div className="align-content-center">
            <More onClick={onClose} style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <hr />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { validateForm }) => handleOpenModal(values, validateForm)}  
        >
          {({ values, handleChange, setFieldValue, touched, errors, }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="1rem" padding="1rem">
                <Autocomplete
                  options={reasonOptions}
                  getOptionLabel={(option) => option.label}
                  value={reasonOptions.find((option) => option.value === values.cancel_reason) || null}
                  onChange={(_, value) => setFieldValue('cancel_reason', value?.value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={RequiredLabel('Reason Cancellation Category', true)}
                      variant="outlined"
                      error={Boolean(touched.cancel_reason && errors.cancel_reason)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#0C7DED',
                          },
                        },
                      }}
                    />
                  )}
                />

                <Autocomplete
                  options={reasonTerminateOptions}
                  getOptionLabel={(option) => option.label}
                  value={reasonTerminateOptions.find((option) => option.value === values.termination_reason) || null}
                  onChange={(_, value) => setFieldValue('termination_reason', value?.value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={RequiredLabel('Reason to Terminate Cycle', true)}
                      variant="outlined"
                      error={Boolean(touched.termination_reason && errors.termination_reason)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#0C7DED',
                          },
                        },
                      }}
                    />
                  )}
                />

                <TextField
                  label="Remark"
                  variant="outlined"
                  value={values.remark}
                  onChange={handleChange}
                  name="remark"
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#0C7DED',
                      },
                    },
                  }}
                />

                <Box display="flex" justifyContent="flex-end" gap="1rem">
                  <Button variant="outlined" onClick={onClose} className={styles.cancelButton}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.customButton}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>

        <ConfirmationModal
          open={openConfirmDialog}
          handleClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleSubmit}  
          title="Terminate Cycle"
          contentMessage="Are you sure you want to terminate the cycle?"
          type={'save'}
        />
      </div>
    </ThemeProvider>
  );
};

export default TerminateCycleConfirmation;
