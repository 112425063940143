import React from 'react';
import styles from './SemenProcessing.module.css';
import Grid from '@mui/material/Grid';
import { ReactComponent as SemenProcess  } from '../../../assets/SVG/semanProcess.svg';
const retrievalData = {
  date: '22 Jan 2024',
  AndrologistName: 'Kate Humber',
  SpermSource: 'Partner',
  MethodofSemenCollection: 'Emily Johnson',
  Interpretation: 'Nurse Anna',
  DNAFragmentationIndex: '12',
};

const SemenProcessing: React.FC = () => {
  return (
    <div className={styles.container}>
      <Grid container spacing={3} justifyContent="start" alignItems="center">
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Date:</strong>
            <p className={styles.customTextStyle}>{retrievalData.date}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Andrologist Name:</strong>
            <p className={styles.customTextStyle}>{retrievalData.AndrologistName}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Sperm Source:</strong>
            <p className={styles.customTextStyle}>{retrievalData.SpermSource}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Method of Semen Collection:</strong>
            <p className={styles.customTextStyle}>{retrievalData.MethodofSemenCollection}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Interpretation:</strong>
            <p className={styles.customTextStyle}>{retrievalData.Interpretation}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>DNA Fragmentation Index:</strong>
            <p className={styles.customTextStyle}>{retrievalData.DNAFragmentationIndex}</p>
          </div>
        </Grid>
      </Grid>
      <hr className="mt-4" />
      {/* New row with Andrologist Name */}
      <Grid container spacing={3} justifyContent="start" alignItems="center" className="mt-4">
        {/* First Grid item: Sperm Count */}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Sperm Count</strong>
            <div className={`${styles.containerprofile} mt-4`}>

              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>John Doe</p>
                <p className={styles.hello}>Your Total Sperm count has been identified <br /> as abnormal, with a value of 17 million/ejaculate.</p>
                <p className={styles.funnyText}>17 million/ejaculate</p>
              </div>
            </div>


          </div>

          <div className={styles.textField}>
            <div className={`${styles.containerprofile} mt-4`}>
              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>Reference</p>
                <p className={styles.hello}>The normal reference range typically falls within the range of 217 million/ejaculate.</p>
                <p className={styles.funnyText}>217 million/ejaculate</p>
              </div>
            </div>


          </div>

        </Grid>

        {/* Second Grid item: Sperm Morphology */}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Sperm  Morphology</strong>
            <div className={`${styles.containerprofile} mt-4`}>

              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>John Doe</p>
                <p className={styles.hello}>Your Total Sperm count has been identified <br /> as abnormal, with a value of 17 million/ejaculate.</p>
                <p className={styles.funnyText}>17 million/ejaculate</p>
              </div>
            </div>


          </div>

          <div className={styles.textField}>
            <div className={`${styles.containerprofile} mt-4`}>
              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>Reference</p>
                <p className={styles.hello}>The normal reference range typically falls within the range of 217 million/ejaculate.</p>
                <p className={styles.funnyText}>217 million/ejaculate</p>
              </div>
            </div>


          </div>

        </Grid>

        {/* Third Grid item: Sperm Total Motility */}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Sperm Total Motility</strong>
            <div className={`${styles.containerprofile} mt-4`}>

              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>John Doe</p>
                <p className={styles.hello}>Your Total Sperm count has been identified <br /> as abnormal, with a value of 17 million/ejaculate.</p>
                <p className={styles.funnyText}>17 million/ejaculate</p>
              </div>
            </div>


          </div>

          <div className={styles.textField}>
            <div className={`${styles.containerprofile} mt-4`}>
              <span className={styles.semenProcessContainer}>
                <SemenProcess />
              </span>
              <div className={styles.textContainer}>
                <p className={styles.name}>Reference</p>
                <p className={styles.hello}>The normal reference range typically falls within the range of 217 million/ejaculate.</p>
                <p className={styles.funnyText}>217 million/ejaculate</p>
              </div>
            </div>


          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SemenProcessing;
