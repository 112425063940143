/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-deprecated */
import * as React from 'react';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  minWidth: '40rem',
  minHeight: '10rem',
  height: 'fit-content',
  bgcolor: 'background.paper',
  border: '2px solid #626262',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem'
};

interface ConfirmationModelProps {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  message: string;
}

const ConfirmationModel: React.FC<ConfirmationModelProps> = ({
  open,
  handleCancel,
  handleConfirm,
  message,
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography fontFamily={'nunito'} id="modal-modal-title" variant="h5" component="p" color='#626262'>
              Confirm
        </Typography>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
          <WarningAmberIcon sx={{color: '#E17E61', height: '2rem'}}/>
          <div>
            <Typography fontWeight={600} fontFamily={'nunito'} id="modal-modal-title" variant="h6" component="p">
              {message}
            </Typography>
          </div>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
          <Button sx={{color: '#626262',}} variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{background: '#626262', color: 'white'}} variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

let modalRoot = document.getElementById('modal-root');

if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  document.body.appendChild(modalRoot);
}

export const showConfirmationModal = ({
  message,
  onConfirm,
  onCancel,
}: {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const handleConfirm = () => {
    onConfirm();
    cleanup();
  };

  const handleCancel = () => {
    onCancel();
    cleanup();
  };

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(modalRoot!);
  };

  ReactDOM.render(
    <ConfirmationModel
      open={true}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      message={message}
    />,
    modalRoot
  );
};