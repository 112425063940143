/* eslint-disable @typescript-eslint/no-unused-vars */
 
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Grid, TextField, MenuItem, Button, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modals.module.css';
import { convertDateToISOWithOffset, formatDateToLocalYYYYMMDD } from '../../services/time';
import { createEvent, EventAppointment, EventCycleRequest, getRooms, getTables, getUsersByClinicOnly, OptionType, Room, Table } from './helper.stim';
import { PaginatedResponse, UserBasic } from '../../types';
import { useClinic } from '../../components/ClinicProvider';

interface EventModelProps {
  scheduleEventModalOpen: boolean;
  handleScheduleModalClose: () => void;
  eventDate: Date;
  patient: number;
  partner?: number;
  cycle: number;
  id?: string;
}

const EventModel: React.FC<EventModelProps> = ({ scheduleEventModalOpen, handleScheduleModalClose, eventDate, patient, partner, cycle, id }) => {
  const [doctors, setDoctors] = useState<OptionType[]>([]);
  const [tables, setTables] = useState<OptionType[]>([]);
  const [rooms, setRooms] = useState<OptionType[]>([]);
  const {ClinicId} = useClinic();

  // useEffect(() => {
  //   if (id) {
      
  //   }
  // }, []);

  useEffect(() => {
    getUsersByClinicOnly(ClinicId)
      .then((paginated: PaginatedResponse<UserBasic[]>) => paginated.objects)
      .then((users: UserBasic[]) => {
        const userOptions: OptionType[] = users.map((user: UserBasic) => ({
          label: `${user.first_name} ${user.last_name}`,
          value: user.id.toString(),
        }));
        setDoctors(userOptions);
      });

    getRooms()
      .then((paginated: PaginatedResponse<Room[]>) => paginated.objects)
      .then((rooms: Room[]) => {
        const roomOptions: OptionType[] = rooms.map((room: Room) => ({
          label: room.name,
          value: room.id.toString(),
        }));
        setRooms(roomOptions);
      });

    getTables()
      .then((paginated: PaginatedResponse<Table[]>) => paginated.objects)
      .then((tables: Table[]) => {
        const roomOptions: OptionType[] = tables.map((table: Table) => ({
          label: table.name,
          value: table.id.toString(),
        }));
        setTables(roomOptions);
      });  
  }, [ClinicId]);

  const attrToFormikFieldMap: Record<
  string,
  string
> = {
  appointment_date: 'date',
  start_datetime: 'fromTime',
  end_datetime: 'toTime',
  user: 'doctor',
  embryologist: 'embryologist',
  table: 'OTtable',
  room: 'operationTheater',
  remark: 'remark',
};
  

  const validationSchema = Yup.object({
    event: Yup.string().required('This field is required'),
    date: Yup.string().required('This field is required'),
    fromTime: Yup.string().required('This field is required'),
    toTime: Yup.string()
      .required('This field is required')
      .test('is-later', 'To Time cannot be less than From Time', function (value) {
        const { fromTime } = this.parent;
        if (!fromTime || !value) return true; // If fromTime or value aren't set yet, skip
        const parseTime = (time: string) => {
          const [h, m] = time.split(':').map(Number);
          return h * 60 + m;
        };
        return parseTime(value) > parseTime(fromTime);
      }),
    doctor: Yup.string().required('This field is required'),
    embryologist: Yup.string().required('This field is required'),
    operationTheater: Yup.string().required('This field is required'),
    OTtable: Yup.string().required('This field is required'),
    remark: Yup.string().required('This field is required'),
  });
  const formik = useFormik({
    initialValues: {
      event: '',
      date: formatDateToLocalYYYYMMDD(eventDate),
      fromTime: '',
      toTime: '',
      doctor: '',
      embryologist: '',
      operationTheater: '',
      OTtable: '',
      remark: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const start = new Date(eventDate);
      start.setHours(
        parseInt(values.fromTime.split(':')[0]),
        parseInt(values.fromTime.split(':')[1])
      );

      const end = new Date(eventDate);
      end.setHours(
        parseInt(values.toTime.split(':')[0]),
        parseInt(values.toTime.split(':')[1])
      );

      const stimApp: EventAppointment = {
        user: parseInt(values.doctor),
        appointment_date: formatDateToLocalYYYYMMDD(eventDate),
        start_datetime: convertDateToISOWithOffset(start),
        end_datetime: convertDateToISOWithOffset(end),
        patient: patient,
        partner: partner,
        room: parseInt(values.operationTheater),
        table: parseInt(values.OTtable),
        status: 'SCHEDULED',
        remark: values.remark,
        embryologist: parseInt(values.embryologist),
        type: 'APPOINTMENT',
      };

      const eventBody: EventCycleRequest = {
        event_type: values.event as 'OPU' | 'ET' | 'LP'| 'FA',
        date: formatDateToLocalYYYYMMDD(eventDate),
        stim_appointment: stimApp,
        cycle: cycle,
      };

      createEvent(eventBody)
        .then(() => {
          handleScheduleModalClose();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.type === 'validation_error') {
            const serverErrors = error.response.data.errors;
            const formikErrors: Record<string, string> = {};

            serverErrors.forEach((err: { attr: string; detail: string }) => {
              const formikField = attrToFormikFieldMap[err.attr.split('.')[1]];
              if (formikField) {
                formikErrors[formikField] = err.detail;
              }
            });

            formik.setErrors(formikErrors);
          }
        });
    },
  });

  return (
    <Modal open={scheduleEventModalOpen} onClose={handleScheduleModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Schedule Event
          </Typography>
          <IconButton aria-label="close" onClick={handleScheduleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={
                  <>
                    Schedule <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="event"
                value={formik.values.event}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                className="mt-0"
                error={formik.touched.event && Boolean(formik.errors.event)}
                helperText={formik.touched.event && formik.errors.event}
              >
                <MenuItem key="oocyte-retrieval" value="OPU">
                  OPU
                </MenuItem>
                <MenuItem key="embryo-transfer" value="ET">
                  ET
                </MenuItem>
                <MenuItem key="oocyte-retrieval" value="FA">
                  FA
                </MenuItem>
                <MenuItem key="embryo-transfer" value="LP">
                  LP
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={true}
                fullWidth
                label="Date *"
                name="date"
                type="date"
                value={formik.values.date}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    From Time <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="fromTime"
                type="time"
                value={formik.values.fromTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
                error={formik.touched.fromTime && Boolean(formik.errors.fromTime)}
                helperText={formik.touched.fromTime && formik.errors.fromTime}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    To Time <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="toTime"
                type="time"
                value={formik.values.toTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
                error={formik.touched.toTime && Boolean(formik.errors.toTime)}
                helperText={formik.touched.toTime && formik.errors.toTime}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={doctors}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) =>
                  formik.setFieldValue('doctor', value?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Doctor *"
                    margin="normal"
                    error={
                      formik.touched.doctor && Boolean(formik.errors.doctor)
                    }
                    helperText={formik.touched.doctor && formik.errors.doctor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={doctors}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) =>
                  formik.setFieldValue('embryologist', value?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Embryologist *"
                    margin="normal"
                    error={
                      formik.touched.embryologist && Boolean(formik.errors.embryologist)
                    }
                    helperText={formik.touched.embryologist && formik.errors.embryologist}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={rooms}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) =>
                  formik.setFieldValue('operationTheater', value?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operation Theater *"
                    margin="normal"
                    error={
                      formik.touched.operationTheater && Boolean(formik.errors.operationTheater)
                    }
                    helperText={formik.touched.operationTheater && formik.errors.operationTheater}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={tables}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) =>
                  formik.setFieldValue('OTtable', value?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="OT table *"
                    margin="normal"
                    error={
                      formik.touched.OTtable && Boolean(formik.errors.OTtable)
                    }
                    helperText={formik.touched.OTtable && formik.errors.OTtable}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={
                  <>
                    Remark <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="remark"
                rows={3}
                multiline
                value={formik.values.remark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                className="mt-0"
                error={formik.touched.remark && Boolean(formik.errors.remark)}
                helperText={formik.touched.remark && formik.errors.remark}
              />
            </Grid>
          </Grid>
          <Box className={styles.modalActions}>
            <Button variant="outlined" onClick={handleScheduleModalClose} className={styles.modalCancelBtn}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" className={styles.modalSaveBtn}>
              Confirm Scheduled
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EventModel;