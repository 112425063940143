import { Modal, Box, Typography, Grid, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import styles from './Modals.module.css';
import { createTest, TestRequest } from './helper.stim';
import { useAlert } from '../../components/alertProvider';
import { formatDateToLocalYYYYMMDD } from '../../services/time';

interface HormoneValueModalProps {
    hormonesModalOpen: boolean,
    handleHormonesModalClose: () => void,
    hormoneId: number,
    patientId: number,
    date: Date,
    cycle: number
}

const HormoneValueModal: React.FC<HormoneValueModalProps> = ({hormonesModalOpen, handleHormonesModalClose, patientId, date, cycle, hormoneId}) => {
  const {addAlert} = useAlert();
    
  const [hormonesFormData, setHormonesFormData] = useState({
    hormoneValue: '',
  });

  const handleHormonesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setHormonesFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleHormonesSubmit = () => {
    const body: TestRequest = {
      patient: patientId,
      laboratory_test: hormoneId,
      results: parseInt(hormonesFormData.hormoneValue),
      date: formatDateToLocalYYYYMMDD(date),
      cycle: cycle
    };
    createTest(body).then(() => {
      addAlert('Scheduled Hormone test.', 'success');
    }).catch(() => addAlert('Failed to create Hormone test', 'error'));
    handleHormonesModalClose();
  };
      
  return (
    <Modal open={hormonesModalOpen} onClose={handleHormonesModalClose}>
      <Box className={`${styles.modal} ${styles.modalSmall}`}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
              Hormones
          </Typography>
        </Box>
        <Grid container spacing={2} className={styles.modalBody}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={<>
                  Hormone Value <span style={{ color: 'red' }}> * </span>
              </>}
              name="hormoneValue"
              placeholder="Hormone Value"
              value={hormonesFormData.hormoneValue}
              onChange={handleHormonesChange}
              margin="normal"
              InputLabelProps={{ shrink: true }}

            />
          </Grid>
        </Grid>
        <Box className={styles.modalActions}>
          <Button variant="outlined" onClick={handleHormonesModalClose} className={styles.modalCancelBtn}>
              Cancel
          </Button>
          <Button variant="contained" onClick={handleHormonesSubmit} className={styles.modalSaveBtn}>
              Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HormoneValueModal;