import * as Yup from 'yup';
import { getRequest, postRequest } from '../../services/httpService';

export interface CycleData {

  procedure: string;
  indication: string;
  medication_protocol: string;
  cycle_creator: string;
  cycle_coordinator: string;
  cycle_provider: string;
  cycle_start_date: string; 
  cycle_end_date: null; 
  lmp_date: string;
  cycle_warning: string;
  remarks: string | null; 
}
export const initialValues: CycleData = {
  procedure: '',
  indication: '',
  medication_protocol: '',
  cycle_creator: '',
  cycle_coordinator: '',
  cycle_provider: '',
  cycle_start_date: '', 
  cycle_end_date: null, 
  lmp_date: '',
  cycle_warning: '',
  remarks: '',
};
export const validationSchema = Yup.object({
  procedure: Yup.number().nullable(),
  medication_protocol: Yup.number().required('Medication Protocol is required'),
  cycle_provider: Yup.string().nullable(),
  cycle_coordinator: Yup.string().nullable(),
  cycle_creator: Yup.string().nullable(),
  cycle_start_date: Yup.date()
    .nullable()
    .required('Cycle Start Date is required'),
  cycle_end_date: Yup.date().nullable(),
  lmp_date: Yup.date()
    .required('LMP Date is required')
    .typeError('LMP Date must be a valid date') 
    .test('is-before-cycle-start', 'LMP Date must be earlier than Cycle Start Date', function(value) {
      const { cycle_start_date } = this.parent;
      return !cycle_start_date || (value && new Date(value) < new Date(cycle_start_date));
    }),

  remarks: Yup.string().nullable(),
  status: Yup.string().nullable(),
  patient: Yup.number().nullable(),
  cycle_number: Yup.string().nullable(),
});


export const cycleFields = {
  procedure: true,
  medication_protocol: true,
  cycle_provider: true,
  cycle_coordinator: true,
  cycle_creator: true,
  cycle_start_date: true,
  cycle_end_date: true,
  lmp_date: true,
  remarks: true,
  status: true,
  patient: true,
  cycle_number: true,
};
export interface TouchedFields {
  [key: string]: boolean;
}

export const medicationProtocol = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];






export function putcycleCreatorsearchUrl(role: string,): string {
  return `/users?role=${role}`;
}
export function cycleCreatorsearch( role: string = 'DOCTOR'): Promise<string> {
  return getRequest(putcycleCreatorsearchUrl(role,), { responseType: 'json' });
}



export function postCreateCycleUrl(): string {
  return '/cycle/';
}

export function createCycle(payload: CycleData): Promise<string> {
  return postRequest(postCreateCycleUrl(), payload, { responseType: 'json' });
}



export function cycleCoordinatorsearch(search: string, role: string = 'NURSE'): Promise<string> {
  return getRequest(putcycleCreatorsearchUrl(role,), { responseType: 'json' });
}


// export function cycleProvidersearch(search: string, role: string = 'ADMIN'): Promise<string> {
//   return getRequest(putTerminateCycleUrl(role, search), { responseType: 'json' });
// }


export function getProcedureUrl(): string {
  return '/patient-procedure/';
}

export function getProcedure(): Promise<string> {
  return getRequest(getProcedureUrl(), { responseType: 'json' });
}
