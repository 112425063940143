/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Modals.module.css';
import { createDrug, Drug, DrugPostRequest, getDrug } from './helper.stim';
import { convertDateToISOWithOffset, convertToUTCFormat, formatDateToLocalYYYYMMDD } from '../../services/time';
import da from '@mobiscroll/react/dist/src/i18n/da';
import { AddAlert } from '@mui/icons-material';
import { useAlert } from '../../components/alertProvider';
import { Axios, AxiosError } from 'axios';

interface AddDrugModalProps {
  drugModalOpen: boolean;
  handleDrugModalClose: () => void;
  cycle: number;
  date: Date;
  id: string;
  type: string;
}

const AddDrugModal: React.FC<AddDrugModalProps> = ({
  drugModalOpen,
  handleDrugModalClose,
  cycle,
  date,
  id,
  type
}) => {
  const [med, setmed] = useState<Drug | null>(null);
  const {addAlert} = useAlert();

  useEffect(() => {
    if (id) {
      getDrug(id).then((drug: Drug) => setmed(drug));
    }
  }, [id]);
  const validationSchema = Yup.object({
    dosage: Yup.string().required('Dosage is required'),
    time: Yup.string().required('Time is required'),
    days: Yup.number()
      .required('Number of days is required')
      .positive('Days must be a positive number')
      .integer('Days must be an integer'),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      dosage: '',
      days: '',
      time: ''
    },
    validationSchema,
    onSubmit: (values) => {
      const medType = type==='trigger'? 'TRIGGER': 'STIMULATION';
      const body: DrugPostRequest = {
        drug: parseInt(id),
        drug_type: medType,
        start_date: formatDateToLocalYYYYMMDD(date),
        quantity: parseInt(values.dosage),
        start_time: convertToUTCFormat(values.time),
        number_of_days: parseInt(values.days),
        cycle: cycle
      };
      createDrug(body).catch((error: AxiosError) => addAlert(error.cause?.message.toString() || 'Failed to create dose', 'error'));
      handleDrugModalClose(); // Close modal after submission
    },
  });

  return (
    <Modal open={drugModalOpen} onClose={handleDrugModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Add Stim doses
          </Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', rowGap: '1rem', columnGap: '1.5rem', flexWrap: 'wrap', padding: '1rem'}}>
          <div className={styles.info}><label>Start Date</label><span>{formatDateToLocalYYYYMMDD(date)}</span></div>
          {med && med.name && <div className={styles.info}><label>Name</label><span>{med?.name}</span></div>}
          {med && med.brand_name && <div className={styles.info}><label>Brand</label><span>{med?.brand_name}</span></div>}
          {med && med.strength && <div className={styles.info}><label>Strength</label><span>{med?.strength}</span></div>}
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Dose *"
                name="dosage"
                type="text"
                value={formik.values.dosage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={formik.touched.dosage && Boolean(formik.errors.dosage)}
                helperText={formik.touched.dosage && formik.errors.dosage}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Drug Time *"
                name="time"
                type="time"
                value={formik.values.time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="# Days *"
                name="days"
                type="number"
                value={formik.values.days}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.days && Boolean(formik.errors.days)}
                helperText={formik.touched.days && formik.errors.days}
              />
            </Grid>
          </Grid>
          <Box
            className={styles.modalActions}
            style={{ display: 'flex', justifyContent: 'right' }}
          >
            <Button
              variant="outlined"
              onClick={handleDrugModalClose}
              className={styles.modalCancelBtn}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={styles.modalSaveBtn}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddDrugModal;