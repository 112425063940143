import { getRequest } from '../../services/httpService';



  

export function getcycleListUrl(patientId: string): string {
  return `/cycle/patient/${patientId}/`; 
}

export function getcycleList(patientId: string): Promise<string> {
  return getRequest(getcycleListUrl(patientId), { responseType: 'json' });
}
