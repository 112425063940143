import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, StepIconProps, StepConnector } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { grey } from '@mui/material/colors';
import styles from './CycleOverViewList.module.css';
import Stimulation from './Stimulation/Stimulation';
import OocyteRetrieval from './OocyteRetrieval/OocyteRetrieval';
import SemenProcessing from './SemenProcessing/SemenProcessing';
import Fertilization from './Fertilization/Fertilization';
import EmbryoCulture from './EmbryoCulture/EmbryoCulture';
import EmbryoTransferOutcome from './EmbryoTransferOutcome/EmbryoTransferOutcome';
import Grid from '@mui/material/Grid';
import { CycleDetailsType, getcycleList } from './CycleDetailsHelper';

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderTop: '2px solid #e0e0e0',
  '& .MuiStepConnector-line': {
    transition: 'all 0.3s ease',
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: '#0C7DED',
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: '#4caf50',
  },
}));

function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  return (
    <div
      style={{
        backgroundColor: completed ? '#4caf50' : active ? '#4caf50' : grey[300],
        color: '#fff',
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s ease',
      }}
    >
      {completed ? <CheckCircleIcon /> : <span>{props.icon}</span>}
    </div>
  );
}

const AnimatedStepper = ({ activeStep, setActiveStepCallback }: { activeStep: number; setActiveStepCallback: (step: number) => void }) => {
  const steps = [
    'Stimulation ',
    'Oocyte Retrieval ',
    'Semen Processing ',
    'Fertilization ',
    'Embryo Culture ',
    'Embryo Transfer & Outcome ',
  ];

  const handleStepClick = (index: number) => {
    setActiveStepCallback(index);
  };

  return (
    <div style={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
        sx={{ padding: '0 2rem' }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={true}>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={() => handleStepClick(index)}>
              <span
                className={`${styles.stepstext} ${activeStep === index ? styles.activeStepText : ''}`}
              >
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CycleDetails: React.FC<{ handleCycleListClick: () => void; cycle: any }> = ({ handleCycleListClick, cycle }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [cycleDetails, setCycleDetails] = useState<CycleDetailsType | null>(null);

  useEffect(() => {
    if (cycle) {
      getcycleList(cycle)
        .then((data) => {
          setCycleDetails(data); 
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching cycle details:', error);
        });
    }
  }, [cycle]);

  
  
  const setActiveStepCallback = (step: number) => {
    setActiveStep(step);
  };

  const getStepLabel = (step: number) => {
    const stepLabels = [
      'Stimulation',
      'Oocyte Retrieval',
      'Semen Processing',
      'Fertilization',
      'Embryo Culture',
      'Embryo Transfer & Outcome',
    ];

    return stepLabels[step] || 'Unknown Step';
  };

  return (
    <div style={{ width: '100%', padding: '2rem' }}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} md={2} className={styles.CycleDetails}>
          Cycle Details:
        </Grid>

        <Grid item xs={12} sm={10} md={10}>

          <Grid container spacing={3} alignItems="center">
            {/* Cycle Number */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Number:</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_number || '-'}</p>
              </div>
            </Grid>

            {/* Protocol */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Protocol:</strong>
                <p className={styles.stepstext}>{ '-'}</p>
              </div>
            </Grid>

            {/* Sperm Source */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Sperm Source:</strong>
                <p className={styles.stepstext}>{ '-'}</p>
              </div>
            </Grid>

            {/* LMP */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>LMP:</strong>
                <p className={styles.stepstext}>{cycleDetails?.lmp_date || '-'}</p>
              </div>
            </Grid>

            {/* Cycle End Date */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle End Date:</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_end_date || '-'}</p>
              </div>
            </Grid>

            {/* Coordinator */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Coordinator:</strong>
                <p className={styles.stepstext}>{cycleDetails?.coordinator_name || '-'}</p>
              </div>
            </Grid>

            {/* Additional Details */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Name:</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_name || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Oocyte Source:</strong>
                <p className={styles.stepstext}>{ '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Fertilization:</strong>
                <p className={styles.stepstext}>{ '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Start Date:</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_start_date || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Provider:</strong>
                <p className={styles.stepstext}>{cycleDetails?.provider_name || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Creator:</strong>
                <p className={styles.stepstext}>{ '-'}</p>
              </div>
            </Grid>

            {/* Cycle List Button */}
            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.cycleList} onClick={handleCycleListClick}>
                    Cycle List
                </strong>
              </div>
            </Grid>
          </Grid>
   
        </Grid>
      </Grid>

      <div className={`${styles.boxstepper} mt-4`}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AnimatedStepper activeStep={activeStep} setActiveStepCallback={setActiveStepCallback} />
          </Grid>
        </Grid>
      </div>

      <div className="mt-4">
        <span className={styles.CycleDetailsStep}>
          {getStepLabel(activeStep)} <span className={styles.CycleDetailsStepView}>View</span>
        </span>
      </div>

      <div className="mt-4">
        {
          {
            'Stimulation': cycleDetails?.stimulation_chart ? <Stimulation stimulationChart={cycleDetails.stimulation_chart} /> : <Stimulation/>,
            'Oocyte Retrieval': <OocyteRetrieval />,
            'Semen Processing': <SemenProcessing />,
            'Fertilization': <Fertilization />,
            'Embryo Culture': <EmbryoCulture />,
            'Embryo Transfer & Outcome': <EmbryoTransferOutcome />
          }[getStepLabel(activeStep)] || null
        }
      </div>
    </div>
  );
};

export default CycleDetails;
