import * as Yup from 'yup';
import { putRequest } from '../../services/httpService';

export  interface TerminateCycleData  {
  cancel_reason: string;
  termination_reason: string;
  remark: string | null;
}
export const initialValues: TerminateCycleData = {
  cancel_reason: '',
  termination_reason: '',
  remark: null,
};
export const validationSchema = Yup.object({
  cancel_reason: Yup.string().required('Reason Cancellation Category is required'),
  termination_reason: Yup.string().required('Reason to Terminate Cycle is required'),  
  remark: Yup.string().nullable().notRequired(),
});

export const reasonOptions = [
  { value: 'MEDICAL', label: 'Medical Reasons' },
  { value: 'NON_MEDICAL', label: 'Non-Medical Reasons' },
  { value: 'UNSUCCESSFUL', label: 'Unsuccessful Outcomes' },
  { value: 'DELIVERY', label: 'Delivery' },
];

export const reasonTerminateOptions = [
  // Medical Reasons
  { value: 'OVERDOSE', label: 'Overdose' },
  { value: 'HEALTH_CONSIDERATIONS', label: 'Health Considerations' },
  { value: 'PREMATURE_OVULATION', label: 'Premature Ovulation' },
  { value: 'OVARIAN_CYST_FORMATION', label: 'Ovarian Cyst Formation' },
  { value: 'SPERM_QUALITY_ISSUES', label: 'Sperm Quality Issues' },
  { value: 'POOR_ENDOMETRIAL_RESPONSE', label: 'Poor Endometrial Response' },

  // Non-Medical Reasons
  { value: 'FINANCIAL_CONSTRAINTS', label: 'Financial Constraints' },
  { value: 'EMOTIONAL_TOLL', label: 'Emotional Toll' },
  { value: 'DECISION_TO_PURSUE_OTHER_OPTIONS', label: 'Decision to Pursue Other Options' },
  { value: 'PERSONAL_REASONS', label: 'Personal Reasons' },
  { value: 'AGE_RELATED_CONCERNS', label: 'Age-related concerns' },
  { value: 'LEGAL_OR_REGULATORY_FACTORS', label: 'Legal or Regulatory Factors' },
  { value: 'RELIGIOUS_OR_CULTURAL_BELIEFS', label: 'Religious or Cultural Beliefs' },
  { value: 'NEED_TIME_TO_REASSESS', label: 'Need Time to Reassess' },
  { value: 'LACK_OF_RESOURCES_OR_SUPPORT', label: 'Lack of Available Resources or Support' },
  { value: 'ETHICAL_CONSIDERATIONS', label: 'Ethical Considerations' },
  { value: 'PSYCHOLOGICAL_WELL_BEING', label: 'Psychological Well-Being' },

  // Unsuccessful Outcomes
  { value: 'RETRIEVAL_CANCELLATION', label: 'Retrieval Cancellation' },
  { value: 'NO_FOLLICULAR_DEVELOPMENT', label: 'No Follicular Development' },
  { value: 'NO_EGG_RETRIEVED', label: 'No Egg Retrieved' },
  { value: 'NO_FERTILIZATION', label: 'No Fertilization' },
  { value: 'NO_BLASTULATION', label: 'No Blastulation' },
  { value: 'NO_NORMAL_PGT_EMBRYO', label: 'No Normal PGT Embryo' },
  { value: 'NO_POST_THAW_EMBRYO_SURVIVAL', label: 'No Post Thaw Embryo Survival' },
  { value: 'INSUFFICIENT_FOLLICLE_DEVELOPMENT', label: 'Insufficient Follicle Development' },
  { value: 'OOCYTE_QUALITY_ISSUES', label: 'Oocyte Quality Issues (Poor egg quality)' },
  { value: 'COMPLETE_FERTILIZATION_FAILURE', label: 'Complete Fertilization Failure' },
  { value: 'ICSI_FAILURE', label: 'ICSI Failure (Unsuccessful intracytoplasmic sperm injection)' },
  { value: 'EMBRYO_DEVELOPMENTAL_ARREST', label: 'Embryo Developmental Arrest' },
  { value: 'FRAGMENTED_EMBRYOS', label: 'Fragmented Embryos (High degree of fragmentation)' },
  { value: 'NO_EMBRYO_AVAILABLE_FOR_TRANSFER', label: 'No Embryo Available for Transfer' },
  { value: 'FAILED_THAWING', label: 'Failed Thawing' },
  { value: 'IMPLANTATION_FAILURE', label: 'Implantation Failure' },
  { value: 'BIOCHEMICAL_LOSS', label: 'Biochemical Loss' },
  { value: 'ECTOPIC_PREGNANCY', label: 'Ectopic Pregnancy' },
  { value: 'SAC_WITH_NO_HEART_BEAT', label: 'Sac with No Heart Beat' },
  { value: 'PREGNANCY_LOSS', label: 'Pregnancy Loss' },
  { value: 'MISCARRIAGE', label: 'Miscarriage' },

  // Delivery
  { value: 'CHEMICAL_PREGNANCY', label: 'Chemical Pregnancy' },
  { value: 'LIVE_BIRTH', label: 'Live Birth' },
];



export function putTerminateCycleUrl(Id: number): string {
  return `/cycle/${Id}/status/`;
}


export function putTerminateCycle(Id: number, data: TerminateCycleData): Promise<string> {
  return putRequest(putTerminateCycleUrl(Id), data, { responseType: 'json' });
}