/* eslint-disable no-console */
import React, {useEffect, useMemo, useState} from 'react';
import { TextField, Grid, Tooltip } from '@mui/material';
import addCircle from '../../assets/SVG/add-black.svg';
import styles from './DiagnosisHome.module.css';
import EditableTable from '../customTableComponent';
import { ColumnConfig, GroupConfig, TableRowData } from '../../types';
import InfoIcon from '@mui/icons-material/Info';
import AddDiagnosis from './AddDiagnosis';
import { useLoader } from '../loaderProvider/loader';
import {  getPatientDiagnosis } from './diagnosisHelper';
import { usePatientContext } from '../SubPatinetProvider';

const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'text', editable: false },
  { field: 'code', headerName: 'Code', type: 'text', editable: false },
  { field: 'name', headerName: 'Name', type: 'text', editable: false },
  { field: 'type', headerName: 'Type', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'notes', headerName: 'Notes', type: 'text', editable: false },
];

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'column' },
  { fields: ['code'], type: 'column' },
  { fields: ['name'], type: 'column' },
  { fields: ['type'], type: 'column' },
  { fields: ['doctor'], type: 'column' },
  { fields: ['notes'], type: 'column' },
];

const DiagnosisHome: React.FC<{ onAddClick: () => void }> = ({ onAddClick }) => {
  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize, setDataSize] = useState<number>();
  const [showAddDiagnosis, setShowAddDiagnosis] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { patientId } = usePatientContext();
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedData, setSelectedData] = useState<any | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAction = (id: number) => {
  };


  useEffect(() => {
    showLoader();
    getPatientDiagnosis(patientId)
      .then((res) => { 
        const tableData: TableRowData[] = res.objects.map((item) => ({
          id: item.id,
          date: item.created_on
            ? new Intl.DateTimeFormat('en-GB').format(new Date(item.created_on))
            : '-', // Format date to dd-mm-yyyy
          code: item.diagnosis_code,
          name: item.diagnosis_name,
          type: item.diagnosis_type,
          doctor: `Dr. ${item.doctor_name}`,
          notes: item.notes
            ? item.notes.length > 30
              ? (
                <span
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Tooltip title={item.notes}>
                    <span>
                      <InfoIcon fontSize="small" />
                    </span>
                  </Tooltip>{' '}
                  &nbsp;
                  {item.notes.substring(0, 45)}...
                </span>
              )
              : (
                <span>{item.notes}</span>
              )
            : '-',
        
        }));
  
        setDataSize(res.meta.total_count);
        setData(tableData);
        
      })
      .catch((error) => {
        console.error('Error fetching patient diagnosis:', error);
      })
      .finally(() => {
        hideLoader();
      });
    // eslint-disable-next-line
  }, [patientId, showAddDiagnosis, showLoader, hideLoader,]);


  const filteredData = useMemo(
    () => data.filter(
      (row) =>
        row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm, data]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnChange = (id: number, field: string, value: string) => {

  };
  


  const handleAddClick = () => {
    setSelectedData(null);
    setShowAddDiagnosis(true);
    onAddClick();
  };
  // const handleAddClickEdit = () => {
  //   setShowAddDiagnosis(true);
  //   onAddClick();
  // };
  const handleBackClick = () => {
    setShowAddDiagnosis(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  return (
    <div className={`${styles.home} mt-4`} style={{ display: 'flex', flexDirection: 'column' }}>
      {showAddDiagnosis ? (
        <AddDiagnosis onBackClick={handleBackClick} data={selectedData} />
      ) : (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <TextField
                id="outlined-basic"
                label="Search "
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined" fullWidth />
            </Grid>
            <Grid item xs />
            <Grid item xs={12} sm="auto">

              <button onClick={handleAddClick} style={  {padding: '5px 25px', border: 'none', borderRadius: '7px', fontSize:'larger', fontWeight:'700', backgroundColor:'#F3F3F3F3', color:'#505050' }}>
                <img style={{padding:'5px', width:'40px', height:'40px'}} src={addCircle} alt='add'/>   ADD
              </button>
            </Grid>
          </Grid>

          <div className="mt-4" style={{ flexGrow: 1 }}>
            <EditableTable
              columns={columns}
              data={filteredData}
              actionType="none"
              // onAction={handleAction}
              onChange={handleOnChange}
              onRowClick={handleAction}
              groupConfig={groupConfig}
              dataSize={dataSize}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DiagnosisHome;
