import React, { useEffect, useState } from 'react';
import styles from './Stimulation.module.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Grid';
import { getstimulationChart } from './StimulationHelper';

interface StimulationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stimulationChart?: any;
}

const Stimulation: React.FC<StimulationProps> = ({ stimulationChart }) => {
  const [days, setDays] = useState<{ date: string; name: string }[]>([]);
  const [events, setEvents] = useState<{ [key: string]: { event_type: string; date: string; appointment: number | null } }>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [medicationNames, setMedicationNames] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [medicationsData, setMedicationsData] = useState<any[]>([]);

  useEffect(() => {
    if (stimulationChart) {
      getstimulationChart(stimulationChart)
        .then((data) => {
          const events = data.events;
          const medications = data.medications;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const medicationNames = medications.map((med: any) => med.drug.brand_name);
          setMedicationNames(medicationNames);
          setMedicationsData(medications);

          const eventMap: { [key: string]: { event_type: string; date: string; appointment: number | null } } = {};
          events.forEach((event: { event_type: string; date: string; appointment: number | null }) => {
            eventMap[event.date] = event;
          });
          setEvents(eventMap);
          let smallestEventDate = new Date(Math.min(...events.map((event: { date: string | number | Date }) => new Date(event.date).getTime())));
          let largestEventDate = new Date(Math.max(...events.map((event: { date: string | number | Date }) => new Date(event.date).getTime())));
          const medicationStartDate = medications.length > 0 ? new Date(medications[0].start_date) : null;
          const medicationEndDate = medications.length > 0 ? new Date(medications[0].end_date) : null;
          if (medicationStartDate && medicationEndDate) {
            smallestEventDate = new Date(Math.min(smallestEventDate.getTime(), medicationStartDate.getTime()));
            largestEventDate = new Date(Math.max(largestEventDate.getTime(), medicationEndDate.getTime()));
          }
          const generatedDays: { date: string; name: string }[] = [];
          const currentDate = new Date(smallestEventDate);
          while (currentDate <= largestEventDate) {
            const dateString = currentDate.toISOString().split('T')[0];
            const dayName = currentDate.toLocaleString('en-us', { weekday: 'short' });
            generatedDays.push({ date: dateString, name: dayName });

            currentDate.setDate(currentDate.getDate() + 1);
          }

          setDays(generatedDays);
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.error('Error fetching stimulation chart data:', error);
        });
    }
  }, [stimulationChart]);

  const chartData = [
    { day: 1, LH: 4, Estradiol: 120, FSH: 3, Progesterone: 0.5 },
    { day: 2, LH: 5, Estradiol: 130, FSH: 3.2, Progesterone: 0.5 },
    { day: 3, LH: 5.5, Estradiol: 135, FSH: 3.5, Progesterone: 0.6 },
    { day: 4, LH: 6, Estradiol: 140, FSH: 3.7, Progesterone: 0.6 },
    { day: 5, LH: 6.5, Estradiol: 145, FSH: 3.9, Progesterone: 0.7 },
    { day: 6, LH: 7, Estradiol: 150, FSH: 4.1, Progesterone: 0.7 },
    { day: 7, LH: 7.5, Estradiol: 155, FSH: 4.3, Progesterone: 0.8 },
    { day: 8, LH: 8, Estradiol: 160, FSH: 4.5, Progesterone: 0.8 },
    { day: 9, LH: 8.5, Estradiol: 165, FSH: 4.7, Progesterone: 0.9 },
    { day: 10, LH: 9, Estradiol: 170, FSH: 5, Progesterone: 0.9 },
    { day: 11, LH: 9.5, Estradiol: 175, FSH: 5.2, Progesterone: 1 },
    { day: 12, LH: 10, Estradiol: 180, FSH: 5.4, Progesterone: 1.1 },
    { day: 13, LH: 10.5, Estradiol: 185, FSH: 5.6, Progesterone: 1.2 },
    { day: 14, LH: 11, Estradiol: 190, FSH: 5.8, Progesterone: 1.3 },
    { day: 15, LH: 15, Estradiol: 250, FSH: 6, Progesterone: 2.1 },
    { day: 16, LH: 16, Estradiol: 260, FSH: 6.2, Progesterone: 2.2 },
    { day: 17, LH: 17, Estradiol: 270, FSH: 6.4, Progesterone: 2.3 },
    { day: 18, LH: 18, Estradiol: 280, FSH: 6.6, Progesterone: 2.4 },
    { day: 19, LH: 19, Estradiol: 290, FSH: 6.8, Progesterone: 2.5 },
    { day: 20, LH: 20, Estradiol: 300, FSH: 7, Progesterone: 2.6 },
    { day: 21, LH: 21, Estradiol: 310, FSH: 7.2, Progesterone: 2.7 },
    { day: 22, LH: 22, Estradiol: 320, FSH: 7.4, Progesterone: 2.8 },
    { day: 23, LH: 23, Estradiol: 330, FSH: 7.6, Progesterone: 2.9 },
    { day: 24, LH: 24, Estradiol: 340, FSH: 7.8, Progesterone: 3 },
    { day: 25, LH: 25, Estradiol: 350, FSH: 8, Progesterone: 3.1 },
    { day: 26, LH: 26, Estradiol: 360, FSH: 8.2, Progesterone: 3.2 },
    { day: 27, LH: 27, Estradiol: 370, FSH: 8.4, Progesterone: 3.3 },
    { day: 28, LH: 28, Estradiol: 380, FSH: 8.6, Progesterone: 3.4 },
  ];


  return (
    <div className={styles.container}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center" alignItems="center">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="LH" stroke="#8884d8" />
              <Line type="monotone" dataKey="Estradiol" stroke="#82ca9d" />
              <Line type="monotone" dataKey="FSH" stroke="#ffc658" />
              <Line type="monotone" dataKey="Progesterone" stroke="#ff7300" />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-4">
          <div className={styles.schedulecontainer}>
            <table className={styles.tableContainer}>
              <thead>
                <tr className="mt-4">
                  <th className={styles.tableHeader}>Day</th>
                  {days.map((day, index) => (
                    <th key={index} className={styles.tableHeader}>
                      <span className={styles.dayNumber}>{day.date.split('-')[2]}</span>
                      <span className={styles.dayName}>{day.name}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="mt-4">
                {/* Events Row */}
                <tr className="mt-4">
                  <td className={styles.tableCell}>Events</td>
                  {days.map((day, index) => {
                    const event = events[day.date] || {};
                    const eventClass = event.event_type ? styles.eventCell : '';
                    return (
                      <td
                        key={index}
                        className={eventClass}
                        style={{ '--row-index': 1, '--col-index': index } as React.CSSProperties} 
                      >
                        {event.event_type || ''}
                      </td>
                    );
                  })}
                </tr>

                {/* Medications Rows */}
                {medicationsData.length > 0 &&
          medicationsData.map((medication, index) => (
            <tr className="mt-4" key={index}>
              <td
                className={styles.tableCellsub}
                style={{ '--row-index': index + 2, '--col-index': 0 } as React.CSSProperties} 
              >
                {medication.drug.name} (IU)
              </td>
              {(() => {
                const mergedCells: JSX.Element[] = [];
                let merged = false;
                days.forEach((day, dayIndex) => {
                  const dayDate = new Date(day.date).toISOString().split('T')[0];
                  const medicationStartDate = new Date(medication.start_date).toISOString().split('T')[0];
                  const medicationEndDate = new Date(medication.end_date).toISOString().split('T')[0];
                  if (dayDate === medicationStartDate && !merged) {
                    const rangeDays = Math.floor(
                      (new Date(medication.end_date).getTime() - new Date(medication.start_date).getTime()) /
                      (1000 * 60 * 60 * 24)
                    ) + 1;

                    mergedCells.push(
                      <td
                        key={dayIndex}
                        colSpan={rangeDays}
                        className={styles.doseCell}
                        style={{
                          '--row-index': index + 2,
                          '--col-index': dayIndex + 1,
                        } as React.CSSProperties} 
                      >
                        {medication.quantity}  
                      </td>
                    );
                    merged = true;
                  } else if (dayDate > medicationStartDate && dayDate <= medicationEndDate) {
                    return;
                  } else {
                    mergedCells.push(
                      <td
                        key={dayIndex}
                        style={{
                          '--row-index': index + 2,
                          '--col-index': dayIndex + 1,
                        } as React.CSSProperties} 
                      ></td>
                    );
                  }
                });
                return mergedCells;
              })()}
            </tr>
          ))}
              </tbody>
            </table>
          </div>
        </Grid>

      </Grid>
    </div>
  );
};

export default Stimulation;
