/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid,ThemeProvider, Box, IconButton, TextField } from '@mui/material';
import { theme } from '../../../../../components/Medical_History_Forms/themeprovider';
import EditableTable from '../../../../../components/customTableComponent';
import { ColumnConfig, GroupConfig, TableRowData } from '../../../../../types';
import { getInvestigation } from '../../../../../services/apiService';
import { useLoader } from '../../../../../components/loaderProvider/loader';
import addCircle from '../../../../../assets/SVG/add-black.svg';
import { usePatientContext } from '../../../../../components/SubPatinetProvider';

type OnDataType = (value: boolean) => void;

const CategoryDetails = ({ onData }: { onData: OnDataType }) => {

  const columnsMale: ColumnConfig[] = [
    { field: 'created_on', headerName: 'Date', type: 'text', editable: false },
    { field: 'identifier', headerName: 'Test Code', type: 'text', editable: false },
    { field: 'name', headerName: 'Test Name', type: 'text', editable: false },
    { field: 'doctor_name', headerName: 'Doctor Name', type: 'text', editable: false },
    { field: 'remark', headerName: 'Remark', type: 'text', editable: false },
    { field: 'action', headerName: 'Action', type: 'text', editable: false },
  ];
  const groupConfigMale: GroupConfig[] = [
    { fields: ['created_on'], type: 'column' },
    { fields: ['identifier'], type: 'column' },
    { fields: ['name'], type: 'column' },
    { fields: ['doctor_name'], type: 'column' },
    { fields: ['remark'], type: 'column' },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const { patientId } = usePatientContext();
  const { showLoader, hideLoader } = useLoader();
  const onDataRef = useRef<OnDataType | null>(null); // Initialize with null
  
  useEffect(() => {
    onDataRef.current = onData;
  }, [onData]);
  

  const onBack = () => {
    onData(true);
  };

  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize, setDataSize] = useState<number>(0);
  const handleOnChange = (id: number, field: string, value: string) => {};


  useEffect(()=>{
    showLoader();
    getInvestigation(`?patient_id=${patientId}`)
      .then((res) => {
        const mappedData = res.objects.map((obj) => ({
          created_on:obj.created_on 
            ? new Intl.DateTimeFormat('en-GB').format(new Date(obj.created_on)) 
            : '-', // Format date to dd-mm-yyyy
          doctor_name: obj.doctor_first_name? `Dr. ${obj.doctor_first_name} ${obj.doctor_last_name}`.trim() : '-', 
          id: obj.id,
          identifier: obj.laboratory_test.identifier,
          name: obj.laboratory_test.name,
          remark: obj.remark ? obj.remark : '-',
        }));

        setDataSize(res.meta.total_count);
        setData(mappedData);
        hideLoader();

      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching investigations:', error);
      })
      .finally(() => {
        hideLoader();

      });

  },[patientId, showLoader, hideLoader]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  
  const filteredData = useMemo(
    () => data.filter(
      (row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm, data]
  );


  return (
    <Box mt={3}>
      <ThemeProvider theme={theme}>
        <div>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <TextField
                id="outlined-basic"
                label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined" fullWidth />
            </Grid>
            <Grid item xs />
            <Grid item xs={12} sm="auto">

              <button onClick={onBack} style={  {padding: '5px 25px', border: 'none', borderRadius: '7px', fontSize:'larger', fontWeight:'700', backgroundColor:'#F3F3F3F3', color:'#505050' }}>
                <img style={{padding:'5px', width:'40px', height:'40px'}} src={addCircle} alt='add'/> ADD
              </button>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} marginTop={2} >
            <Grid item xs={5} display="flex" justifyContent="start">
              <IconButton onClick={onBack}>
                <img src={HPIBack} alt="Back" style={{ width: '2.5rem', height: '2.5rem' }} />
              </IconButton>

            </Grid>

          </Grid> */}

        </div>

        <Box className='mt-4'>
          <Box className='mt-3'>
            <EditableTable
              columns={columnsMale}
              data={filteredData}
              actionType="none"
              onChange={handleOnChange}
              groupConfig={groupConfigMale}
              dataSize={dataSize}
            />
            {data.length === 0 && (
              <Box textAlign="center" padding={2}>
                <p>No data found</p>
              </Box>
            )}
          </Box>
        </Box>

      </ThemeProvider>
    </Box>
  );
};

export default CategoryDetails;