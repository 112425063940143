/* eslint-disable */
/* eslint-disable no-console */
import { Autocomplete, Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Appointment, AppointmentReasons, AppointmentStatus, userListRoleWise, userListWorkingHours } from '../../types';
import { appointmentReasons, updateAppointment, userList } from '../../services/apiService';
import { convertISOToTime } from '../../services/time';
import { useAlert } from '../alertProvider';
import { RequiredLabel, timeDifference } from '../../utils/commonUtils';

type IRescheduled = {
    data: Appointment | undefined;
    onClose: (value: boolean) => void;
}
const Rescheduled: React.FC<IRescheduled> = ({ data, onClose }) => {

  const [appointmentReasonsList, setAppointmentReasonsList] = useState<AppointmentReasons[]>([]);
  const [userListData, setUserListData] = useState<userListWorkingHours[]>([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState<userListRoleWise | undefined>(undefined);

  const { addAlert } = useAlert();

  const [formData, setFormData] = useState({
    selectedAppointmentReason: 0,
    reschedulingreason: '',
    remark: '',
    timefrom: data?.start_datetime,
    timeto: '',
    personnel: 0,
    date: '',
    patinet: data?.patient?.id,
    partner: data?.patient?.partner?.id
  });

  useEffect(() => {
    fetchAppointmentReasons();
  }, []);

  const fetchAppointmentReasons = () => {
    appointmentReasons()
      .then((data) => {
        setAppointmentReasonsList(data.objects);
      })
      .catch((error) => {
        console.error('Error fetching appointment reasons:', error);
      });
  };


  useEffect(() => {
    if (data) {
      const initialReason = appointmentReasonsList.find(
        (reason) => reason.reason === data.appointment_reason__reason
      );

      setFormData((prev) => ({
        ...prev,
        selectedAppointmentReason: initialReason?.id || 0,
        timefrom: convertISOToTime(data?.start_datetime || '', false),
        timeto: convertISOToTime(data?.end_datetime || '', false),
        personnel: data?.user?.id,
        date: data?.appointment_date,
      }));

      setSelectedPersonnel(data?.user);
    }
  }, [data, appointmentReasonsList]);


  const getAvailableUsers = useCallback(() => {

    userList()
      .then((data: any) => {
        setUserListData(data?.objects);
      })
      .catch((error) => {
        console.error('Error fetching personnel by role:', error);
      });

  }, [formData.date, data?.appointment_date, data?.end_datetime, data?.department__id, data?.start_datetime]);

  useEffect(() => {
    getAvailableUsers();
  }, [getAvailableUsers]);


  const handleInputChange = (name: string, value: string | null | userListRoleWise) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'personnel' && value ? (value as userListRoleWise).user : value,
    }));
  };



  const handleRescheduled = () => {
    const start = formData.timefrom || '';
    const end = formData.timeto || '';

    const timeDiff = timeDifference(start, end);

    const params = {
      'user': selectedPersonnel?.id,
      'appointment_date': formData.date,
      'start_datetime': formData.timefrom,
      'end_datetime': formData.timeto,
      'appointment_reason': formData.selectedAppointmentReason,
      'patient': data?.patient?.id,
      ...(data?.patient?.partner && {'partner': data?.patient?.partner?.id,}),
      'remark': formData.remark,
      'status': AppointmentStatus.SCHEDULED,
      'cancel_or_reschedule_reason': formData.reschedulingreason
    };

    if (!params.remark.length || !params.cancel_or_reschedule_reason.length) {
      addAlert('Kindly fill all the required fields', 'error');
    } else if(timeDiff < 5 || timeDiff > 300) {
      addAlert('Time slot range should be in between 5 mins to 300 mins (5 hours)', 'error');
    } else {

      updateAppointment(data?.id || 0, params).then((data) => {

        addAlert('Appointment has been rescheduled', 'success');
        onClose(false);
      }).catch((err) => {
        err?.response?.data?.errors?.map((err: { detail: string }) => {
          return addAlert(err.detail, 'error');
        });
      });
    }
  };

  const renderDetail = (label: string, value: string | undefined, isDisabled: boolean, type?: string, width?: number, rows?: number) => {
    const today = new Date().toISOString().split('T')[0];
    return(
      <Grid item xs={12} sm={6} md={width ?? 4} justifyContent='space-between'>
        <TextField
          label={RequiredLabel(label, true)}
          value={value}
          variant="outlined"
          type={type}
          fullWidth
          multiline={rows ? true : false}
          disabled={isDisabled}
          inputProps={{
            min: today
          }}
          InputLabelProps={{
            sx: {
              color: '#333',
            },
          }}
          rows={rows}
          onChange={(e) => handleInputChange(label.toLowerCase().replace(/ /g, ''), e.target.value)}
        />
      </Grid>
    );
  };


  return (
    <Box>
      <Divider style={{ height: '1px', background: '#595959', marginTop: 20, marginBottom: 20 }} />

      <Grid container spacing={3} mt={2}>
        {renderDetail('First Name', data?.patient?.first_name, true)}
        {renderDetail('Last Name', data?.patient?.last_name, true)}
        {renderDetail('Sex Assigned At Birth', data?.patient?.gender, true)}
        {renderDetail('Department', data?.department__name, true)}
        {renderDetail('Previous Time Slot', `${convertISOToTime(data?.start_datetime || '', true)} - ${convertISOToTime(data?.end_datetime || '', true)}`, true)}
        <Grid item xs={12} sm={6} md={4} justifyContent='space-between'>
          <FormControl fullWidth size="small">
            <InputLabel id="appointment-reason-label">Appointment Reason *</InputLabel>
            <Select
              labelId="appointment-reason-label"
              size='medium'
              sx={{ height: 55 }}
              value={formData.selectedAppointmentReason}
              onChange={(e) =>  setFormData((prevFormData) => ({
                ...prevFormData,
                selectedAppointmentReason: Number(e.target.value),
              }))}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    width: '280px',
                  },
                },
              }}
              label="Appointment Reason *"
            >
              {appointmentReasonsList?.map((reason) => (
                <MenuItem key={reason.id} value={reason.id} style={{
                  whiteSpace: 'normal',
                  wordWrap: 'break-word'
                }}>
                  {reason.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {renderDetail('Rescheduling Reason ', formData.reschedulingreason, false, '', 12)}

        <Grid item xs={12} sm={6} md={4} justifyContent='space-between'>
          <FormControl fullWidth size="small">
            <Autocomplete
              options={userListData}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
              value={selectedPersonnel || null}
              onChange={(_e, personnelNewValue: userListRoleWise | null) => {
                setSelectedPersonnel(personnelNewValue || undefined);
                handleInputChange('personnel', personnelNewValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={data?.department__name === 'Admin' ? 'Personnel' : data?.department__name === 'Consultation' ? 'Doctor Name' :
                  data?.department__name === 'Ultrasound' ? 'Doctor Name' : data?.department__name === 'Surgery' ? 'Doctor Name' :
                    data?.department__name === 'Pathology' ? 'Pathologist' : 'Doctor Name'} variant="outlined"/>
              )}
            />
          </FormControl>
        </Grid>

        {renderDetail('Date', formData.date, false, 'date')}
        {renderDetail('Time From', formData.timefrom, false, 'time')}
        {renderDetail('Time To', formData.timeto, false, 'time')}
        {renderDetail('Remark', formData.remark, false, '', 12, 3)}
      </Grid>


      <Stack direction='row' mt={3} gap={3}>
        <Button fullWidth sx={{ color: '#232323', borderRadius: 3, borderColor: '#00000', borderWidth: 2, fontWeight: 700, fontFamily: 'Montserrat', fontSize: '16px' }} variant='outlined' onClick={() => onClose(false)}>Keep It</Button>
        <Button fullWidth sx={{ color: '#FFFFFF', borderRadius: 3, borderWidth: 2, fontWeight: 700, fontFamily: 'Montserrat', fontSize: '16px', background: '#000000' }} variant='outlined' onClick={handleRescheduled}>Rescheduled</Button>
      </Stack>
    </Box>
  );
};

export default Rescheduled;