import React, { useEffect, useState } from 'react';
import styles from './CycleOverViewList.module.css';
import { getcycleList } from './CycleOverviewListHelper';
import active from '../../assets/SVG/active.svg';
import inactive from '../../assets/SVG/Inactive.svg';
import { Button, Typography, Dialog, DialogContent, TextField, Grid } from '@mui/material';
import CreateNewCycle from './CreateNewCycle';
import TerminateCycleConfirmation from './TerminateCycleConfirmation';
import CycleDetails from './CycleDetails';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import UpdateDisabledSharpIcon from '@mui/icons-material/UpdateDisabledSharp';
import { usePatientContext } from '../SubPatinetProvider';
const CycleOverviewList = () => {
  const [isCreateCycleDialogOpen, setCreateCycleDialogOpen] = React.useState(false);
  const [isTerminateCycleDialogOpen, setTerminateCycleDialogOpen] = React.useState(false);
  const [isCycleDetailsVisible, setCycleDetailsVisible] = useState(false);
  const [selectedIdTerminate, setSelectedIdTerminate] = useState<string | null>(null);
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const { patientId } = usePatientContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cyclesData, setCyclesData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredCycles, setFilteredCycles] = useState<any[]>([]);  
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCycle, setSelectedCycle] = useState(null);
  const handleOpenDialog = () => {
    setCreateCycleDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setCreateCycleDialogOpen(false);
  };

  const handleTerminateCloseDialog = () => {
    setTerminateCycleDialogOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (cycle: any) => {
    setCycleDetailsVisible(true);
    setSelectedCycle(cycle.id); 
  };
  
  const handleCycleListClick = () => {
    setCycleDetailsVisible(false);
  };

  useEffect(() => {
    showLoader();
    getcycleList(String(patientId))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        setCyclesData(data);
        setFilteredCycles(data);  
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        if (error?.response?.data?.errors && Array.isArray(error.response.data.errors)) {
          const errorMessage = error.response.data.errors.map((err: { detail: string }) => err.detail).join(', ');
          addAlert(errorMessage, 'error');
          setCyclesData([]);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error creating cycle:', error);
          addAlert('Error creating cycle', 'error');
        }
      });
  }, [showLoader, hideLoader, addAlert, patientId, isCreateCycleDialogOpen, isTerminateCycleDialogOpen]);


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCycles(cyclesData);  
    } else {
      const filtered = cyclesData.filter((cycle) =>
        (cycle.cycle_number && cycle.cycle_number.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (cycle.cycle_name && cycle.cycle_name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredCycles(filtered);  
    }
  }, [searchTerm, cyclesData]);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() === '') {
      setFilteredCycles(cyclesData);  
    }
  };
  
  return (
    <div style={{ padding: '1rem' }}>
      {isCycleDetailsVisible ? (
        <CycleDetails handleCycleListClick={handleCycleListClick} cycle={selectedCycle}/>
      ) : (
        <>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={6} xl={6}>
              <div>
                <Typography variant="h6" className={styles.CycleList}>
        Cycle List
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search by cycle number & cycle name"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    width: '500px',
                    marginRight: '16px',
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.AddNewCycle}
                  onClick={handleOpenDialog}
                >
                  <span className={styles.AddNewCycleText}>Create New Cycle</span>
                </Button>
              </div>
            </Grid>
          </Grid>

          <div className="mt-4">
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table className={styles.custom_table}>
                <thead className={styles.custom_table_head}>
                  <tr>
                    <th className={styles.custom_table_cell}>Cycle Status</th>
                    <th className={styles.custom_table_cell}>Cycle Code</th>
                    <th className={styles.custom_table_cell}>LMP</th>
                    <th className={styles.custom_table_cell}>ART</th>
                    <th className={styles.custom_table_cell}>Protocol</th>
                    <th className={styles.custom_table_cell}>Indication</th>
                    <th className={styles.custom_table_cell}>Sperm Source</th>
                    <th className={styles.custom_table_cell}>#Follicules</th>
                    <th className={styles.custom_table_cell}>OPU</th>
                    <th className={styles.custom_table_cell}>#Oocyte</th>
                    <th className={styles.custom_table_cell}>#Fertilized</th>
                    <th className={styles.custom_table_cell}>#Cleaved</th>
                    <th className={styles.custom_table_cell}>#Cryo</th>
                    <th className={styles.custom_table_cell}>Embryo Transfer</th>
                    <th className={styles.custom_table_cell}>BHCG/UPT</th>
                    <th className={styles.custom_table_cell}>Pregnancy Confirmation</th>
                    <th className={styles.custom_table_cell}>Remaining Embryo’s</th>
                    <th className={styles.custom_table_cell}>Cancel Remark</th>
                    <th className={styles.custom_table_cell}></th>
                  </tr>
                </thead>
                <tbody className={styles.custom_table_body}>
                  {filteredCycles.length === 0 ? (
                    <tr>
                      <td colSpan={19} className={styles.noDataFound}>
            No data found
                      </td>
                    </tr>
                  ) : (
                    filteredCycles.map((cycle) => (
                      <tr
                        key={cycle.id}
                        className={styles.custom_table_row}
                        onClick={() => handleRowClick(cycle)}
                      >
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          <img
                            src={cycle.status === 'ACTIVE' ? active : inactive}
                            alt={cycle.status}
                            className={styles.status_icon}
                          />
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.cycle_code_cell}`}>
                          {cycle.cycle_number || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.lmp_date || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.ART || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.medication_protocol || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.procedure?.indication || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.procedure?.donor_info?.sperm_source?.[0] || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.follicules || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.opu || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.procedure?.donor_info?.oocyte_source?.[0] || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.fertilized || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.cleaved || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.cryo || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={`${styles.squareBox} ${styles.greenBox}`}></div>
                            <span style={{ marginLeft: '0.5rem' }}>
                              {cycle.embryo_transfer_fresh || '-'}
                            </span>
                            <div
                              className={`${styles.squareBox} ${styles.secondBox}`}
                              style={{ marginLeft: '1rem' }}
                            ></div>
                            <span style={{ marginLeft: '0.5rem' }}>
                              {cycle.embryo_transfer_frozen || '-'}
                            </span>
                          </div>
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.bhcg_upt || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.pregnancy_confirmation || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.remaining_embryos || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.cancel_remark || '-'}
                        </td>
                        <td className={`${styles.custom_table_cell} ${styles.tb_main_text}`}>
                          {cycle.status === 'ACTIVE' && (
                            <UpdateDisabledSharpIcon
                              onClick={(event: { stopPropagation: () => void; }) => {
                                event.stopPropagation();
                                setSelectedIdTerminate(cycle.id);
                                setTerminateCycleDialogOpen(true);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

  
            <div className="mt-4">
              <div className={styles.boxWrapper}>
                <div className={`${styles.box} ${styles.box1} ${styles.custom_table_cell}`}></div>
                <span className={styles.customtext}>Fresh Embryo</span>
                <div className={`${styles.box} ${styles.box2} ${styles.custom_table_cell}`}></div>
                <span className={styles.customtext}>Frozen Embryo</span>
              </div>
            </div>
          </div>
        </>
      )}
 
  


      {/* Dialog to show CreateNewCycle component */}
      <Dialog
        open={isCreateCycleDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '60rem',
            maxWidth: '70vw',
            padding: '1rem',
          },
        }}
        aria-labelledby="create-cycle-dialog-title"
      >
        <DialogContent
          className="p-0"
          style={{
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <CreateNewCycle onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>

      {/* Dialog to show Terminate Cycle component */}
      <Dialog
        open={isTerminateCycleDialogOpen}
        onClose={handleTerminateCloseDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '60rem',
            maxWidth: '70vw',
            padding: '1rem',
          },
        }}
        aria-labelledby="terminate-cycle-dialog-title"
      >
        <DialogContent
          className="p-0"
          style={{
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <TerminateCycleConfirmation onClose={handleTerminateCloseDialog} cycleId={selectedIdTerminate} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CycleOverviewList;
