/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getStimEvents, OptionType, Stim } from './helper.stim';
import { convertToLocalDateTime, formatDateToYYYYMMDD } from '../../services/time';

interface CycleSelectorProps {
    onCycleChange: (event: React.SyntheticEvent, value: OptionType | null) => void;
    patient: number
}


const CycleSelector: React.FC<CycleSelectorProps> = ({onCycleChange, patient}) => {

  const [stim, setStim] = useState<OptionType[] | null>();
  const [stims, setStims] = useState<Stim[] | null>(); 
  const [selectedStim, setSelectedStim] = useState<Stim | null>();
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>(null);

  useEffect(() => {
    getStimEvents().then((body: { objects: Stim[]; }) => {
      const stims: OptionType[] = body.objects.map((stim: Stim) => ({'label': stim.cycle.cycle_number, 'value': stim.id.toString()}));
      setStims(body.objects);
      setStim(stims);
    });
  }, []);

  useEffect(() => {
    if (stims && selectedStimChart) {
      const selected: Stim = stims.filter((stimulation: Stim) => stimulation.id===selectedStimChart)[0];
      setSelectedStim(selected);
    }
  }, [selectedStimChart, stims]);

  const handleChange = (event: React.SyntheticEvent, value: OptionType | null) => {
    if (value) {
      setSelectedStimChart(parseInt(value.value));
      onCycleChange(event, value);
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingRight: '0.5rem'}}>
            <Typography fontFamily={'Montserrat'} fontWeight={600} fontSize={'18px'} variant='h6'>Cycle Details</Typography>
            <div style={{display: 'flex' , flexDirection: 'row', gap: '0.2rem'}}>
              <Button color='info' variant='text'><Typography sx={{textDecoration: 'underline'}} fontFamily={'Montserrat'} fontWeight={600}  fontSize={'16px'}  variant='h6'>Cycle Overview</Typography></Button>
              {stim && <Autocomplete
                disablePortal
                options={stim}
                onChange={handleChange}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Cycle No." />}
              />}
              <Button color='error' variant='outlined'>Terminate Cycle</Button>
            </div>
          </div>
          
        </AccordionSummary>
        {selectedStim && <AccordionDetails>
          <div className={Styles.detailsContainer}>
            <div className={Styles.info}><label>{'Cycle Name'}</label><span>{selectedStim.cycle.cycle_name}</span></div>
            <div className={Styles.info}><label>{'Cycle No.'}</label><span>{selectedStim.cycle.cycle_number}</span></div>
            <div className={Styles.info}><label>{'Medication Protocol'}</label><span>{selectedStim.cycle.medication_protocol || 'N/A'}</span></div>
            <div className={Styles.info}><label>{'Cycle Provider'}</label><span>{selectedStim.cycle.provider_name}</span></div>
            <div className={Styles.info}><label>{'Cycle Co-ordinator'}</label><span>{selectedStim.cycle.coordinator_name}</span></div>
            <div className={Styles.info}><label>{'Cycle Start date'}</label><span>{formatDateToYYYYMMDD(convertToLocalDateTime(selectedStim.cycle.cycle_start_date))}</span></div>
            <div className={Styles.info}><label>{'LMP date'}</label><span>{selectedStim.cycle.lmp_date ? formatDateToYYYYMMDD(convertToLocalDateTime(selectedStim.cycle.lmp_date)) : 'N/A'}</span></div>
            <div className={Styles.info}><label>{'Remarks'}</label><span>{selectedStim.cycle.remarks || 'N/A'}</span></div>
          </div>
        </AccordionDetails>}
      </Accordion>
    </div>
  );
};

export default CycleSelector;