/* eslint-disable no-console */
import React, { useState } from 'react';
import { Appointment, AppointmentStatus } from '../../types';
import { Box, Divider, Grid, Stack, Button, TextField } from '@mui/material';
import { convertISOToTime } from '../../services/time';
import { cancelAppointment } from '../../services/apiService';
import { useAlert } from '../alertProvider';
import { LabeledWithAsterisk } from '../../utils/commonUtils';

type ICancel = {
    data: Appointment | undefined,
    onClose: (value: boolean) => void;
}

const Cancel:React.FC<ICancel> = ({ data, onClose }) => {
  const [remark, setRemark] = useState<string>('');
  const [cancelationReason, setCancelationReason] = useState<string>('');
  const { addAlert } = useAlert();

  const handleChange = (value: string, name: string ) => {
    if(name === 'Cancelation Reason') {
      setCancelationReason(value);
    } else if(name === 'Remark') {
      setRemark(value);
    }
  };

  const handleCancel = () => {
    const params = { 
      'remark': remark,
      'status': AppointmentStatus.CANCELLED,
      'cancel_or_reschedule_reason': cancelationReason
    };

    if(!params.remark.length || !params.cancel_or_reschedule_reason.length) {
      addAlert('Kindly fill all the required fields', 'error');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cancelAppointment(data?.id || 0, params).then((data) => {
        addAlert('The appointment has been cancelled successfully.', 'success');
        onClose(false);
      }).catch((err) => {
        err?.response?.data?.errors?.map((err: { detail: string }) => {
          return addAlert(err.detail, 'error');
        });
      });
    }
  };

  const renderDetail = (label: string, value: string | undefined, isDisabled: boolean, type?: string, width?: number, rows?: number) => (
    <Grid item xs={12} sm={6} md={width ?? 4} justifyContent='space-between'>
      {
        label === 'Cancelation Reason' ? 
          <TextField label={LabeledWithAsterisk(label, true)} value={cancelationReason}
          
            InputLabelProps={{
              sx: {
                color: '#333',
              },
            }}        
            onChange={(e) => handleChange(e.target.value, label)} type={type} variant="outlined" fullWidth /> : 
          label === 'Remark' ? 
            <TextField label={LabeledWithAsterisk(label, true)} value={remark} InputLabelProps={{
              sx: {
                color: '#333',
              },
            }}   variant='outlined' fullWidth multiline rows={rows} onChange={(e) => handleChange(e.target.value, label)}  /> :
            <TextField label={LabeledWithAsterisk(label, true)} value={value} type={type} variant="outlined" multiline rows={rows} disabled={isDisabled} fullWidth />
      }
    </Grid>
  );

      
  return (
    <Box>
      <Divider style={{ height: '1px', background: '#595959', marginTop: 20, marginBottom: 20 }} />

      <Grid container spacing={3} mt={2}>
        {renderDetail('First Name', data?.patient?.first_name, true)}
        {renderDetail('Last Name', data?.patient?.last_name, true)}
        {renderDetail('Sex Assigned At Birth', data?.patient?.gender, true)}
        {renderDetail('Department', data?.department__name, true)}
        {renderDetail('Personnel', data?.user?.first_name, true)}
        {renderDetail('Previous Time', `${convertISOToTime(data?.start_datetime || '', true)}`, true, 'time')}
        {renderDetail('Appointment Reason', data?.appointment_reason__reason, true, '', 12)}
        {renderDetail('Cancelation Reason', '', false, '', 12)}
        {renderDetail('Remark', '', false, '', 12, 3)}
      </Grid>


      <Stack direction='row' mt={3} gap={3}>
        <Button fullWidth sx={{ color: '#232323', borderRadius: 3, borderColor: '#00000', borderWidth: 2, fontWeight: 700, fontFamily: 'Montserrat', fontSize: '16px' }} variant='outlined' onClick={() => onClose(false)}>Keep It</Button>
        <Button fullWidth sx={{ color: '#FFFFFF', borderRadius: 3, borderWidth: 2, fontWeight: 700, fontFamily: 'Montserrat', fontSize: '16px', background: '#000000' }} variant='outlined' onClick={handleCancel}>Yes, Sure</Button>
      </Stack>
    </Box>
  );
};

export default Cancel;