import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface ConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  type: 'save' | 'delete'| 'warning'; 
  title?: string; 
  content?: React.ReactNode;
}

interface ConfirmationModalProps {
    open: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    type: 'save' | 'delete'| 'warning'; 
    title?: string; 
    contentMessage?: string | React.ReactNode;
  }
  
export function ConfirmationModal({
  open,
  handleClose,
  onConfirm,
  type,
  title = type === 'delete' ? 'Delete Confirmation' : 'Save Confirmation',
  contentMessage = type === 'delete' ? 'Do you want to delete?' : 'Are you sure that you want to save the changes?',
}: ConfirmationModalProps) {
  const confirmButtonStyle = {
    borderColor: type === 'delete' ? 'red' : '#505050',
    color: type === 'delete' ? 'red' : '#505050',
    '&:hover': {
      borderColor: type === 'delete' ? 'red' : '#505050',
      backgroundColor: type === 'delete' ? 'red' : '#505050',
      color: 'white', 
    },
    textTransform: 'none',
  };
  
  const cancelButtonStyle = {
    borderColor: type === 'delete' ? '#505050' : 'red',
    color: type === 'delete' ? '#505050' : 'red',
    '&:hover': {
      borderColor: type === 'delete' ? '#505050' : 'red',
      backgroundColor: type === 'delete' ? '#505050' : 'red',
      color: 'white', 
    },
    textTransform: 'none',
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <DialogTitle id="confirmation-modal-title">
        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-modal-description" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
          <span>
            <CheckCircleIcon sx={{ verticalAlign: 'middle', marginRight: '4px' }} />
            {contentMessage}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" sx={cancelButtonStyle}>
            No
        </Button>
        <Button
        
          onClick={() => {
            onConfirm();
            handleClose();
          }}
          variant="outlined"
          sx={confirmButtonStyle} 
          autoFocus
        >
            Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
  
  
