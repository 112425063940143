/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import { StimulationSchedular } from '../../components/stimSchedular';
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import { usePatientContext } from '../../components/patinetProvider';
import { PatientData } from '../../types';
import CycleSelector from './cycleSelector.stim';
import { OptionType } from './helper.stim';

const StimPage: React.FC = () => {

  const [selectedPatient, setselectedPatient] = useState<PatientData | null>(null);
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>(null);

  const handleDatafromHeader = (data: PatientData) => {
    setselectedPatient(data);
  };

  const handleChange = (event: React.SyntheticEvent, value: OptionType | null) => {
    if (value) {
      setSelectedStimChart(parseInt(value.value));
    }
  };


  return (
    <div className={Styles.stimpage}>
      <div className={Styles.header}>
        <HeaderProfile onData={handleDatafromHeader}/>
      </div>
      <div className={Styles.cycleSelector}>
        {selectedPatient && <CycleSelector patient={selectedPatient.id} onCycleChange={handleChange}/>}
      </div>
      <div className={Styles.scheduler}>
        {selectedStimChart && <StimulationSchedular id={selectedStimChart} height={'75vh'}/>}
      </div>
    </div>
  );
};

export default StimPage;