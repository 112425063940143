/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { alpha, Box, Button, Menu, MenuItem, MenuProps, Modal, Stack, styled, Typography } from '@mui/material';
import registerUserIcon from '../../assets/SVG/register-user.svg';
import rescheduleIcon from '../../assets/SVG/Reschedule.svg';
import cancelIcon from '../../assets/SVG/Cancel.svg';
import checkInIcon from '../../assets/SVG/Check in.svg';
import { ReactComponent as VectorIcon } from '../../assets/SVG/dotline.svg';
import checkOutIcon from '../../assets/SVG/tag-cross.svg';
import { useNavigate } from 'react-router-dom';
import { Appointment, AppointmentStatus, VisitLog } from '../../types';
import Rescheduled from '../appointmentRescheduleModal';
import CustomModal from '../appointmentViewModal';
import Cancel from '../appointmentCancelModal';
import cancelAlertIcon from '../../assets/SVG/Cancel (1).svg';
import closeIcon from '../../assets/SVG/close-circle-event.svg';
import { checkDatePassed } from '../../utils/commonUtils';
import { getVisitLogs, updateAppointmentStatus } from '../../services/apiService';
import { useAlert } from '../alertProvider';

interface ActionsMenuProps {
  appointment: Appointment;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ appointment }) => {
    
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const [viewModal, setViewModalOpen] = useState<boolean>(false);
  const [appSelectedId, setAppSelectedId] = useState<number>(0);
  const [cancelView, setCancelView] = useState<boolean>(false);
  const [rescheduled, setRescheduled] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previousVisits, setPreviousVisits] = useState<Array<VisitLog>>([]);


  const [selectedApp, setSelectedApp] = useState<Appointment | undefined>();
  const [actionsVisibility, setActionsVisibility] = useState({
    isCheckedIn: true,
    isRegister: false,
    isCheckOut: false
  });


  const actionModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '20px'
  };

  useEffect(() => { 
    if (selectedApp?.patient?.id) {
      try {
        const query = 'order_by=created_on'; 

        getVisitLogs(selectedApp?.patient?.id, query)
          .then((logs) => {
            setPreviousVisits(logs.objects);
          })
          .catch((error) => {
            console.error('Error fetching visit logs:', error);
          });
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
  }, [selectedApp?.patient?.id]);


  const updateStatus = (status: string, id: number | undefined) => {

    updateAppointmentStatus(id || 0, status)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {

        setAnchorEl(null);
      })
      .catch((error) => {
        addAlert(`Error: ${error}`, 'error');
        console.error('Error updating status:', error);
      });
  };


  const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement>, data: Appointment) => {
    setSelectedApp(data);
    const isPast = checkDatePassed(data?.appointment_date || '');
    const isToday = data?.appointment_date === new Date().toISOString().split('T')[0];
            
    if (isPast || data?.status === AppointmentStatus.CHECKED_OUT) {
      setViewModalOpen(true);
      setAppSelectedId(data?.id || 0);
    } else {
      setAnchorEl(event.currentTarget);
    }
            

    if(isToday){
      if(data?.status === AppointmentStatus.CHECKED_IN) {
        setActionsVisibility({...actionsVisibility, isCheckedIn: false, isRegister: false, isCheckOut: true});
      } else if(data?.status === AppointmentStatus.CHECKED_OUT && (data?.checked_out_time || data?.checked_in_time)) {
        setAppSelectedId(data?.id || 0);
        setViewModalOpen(true);
      } else {
        setActionsVisibility({...actionsVisibility, isCheckedIn: true, isRegister: true, isCheckOut: false});
      }
    } else if(!isToday) {
      setActionsVisibility({...actionsVisibility, isCheckedIn: false, isRegister: true, isCheckOut: false});
    }
            
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));


  const handleModalClose = () => {
    setRescheduled(false);
    setCancelView(false);
  };

  const handleRescheduleView = () => {
    setRescheduled(true);
    setAnchorEl(null);
    setActionsVisibility({...actionsVisibility,  isCheckedIn: true,
      isRegister: false,
      isCheckOut: false});
  };

  const handleCancelView = () => {
    setCancelView(true);
    setAnchorEl(null);
    setActionsVisibility({...actionsVisibility,  isCheckedIn: true,
      isRegister: false,
      isCheckOut: false});
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={(e) => handleStatusClick(e, appointment)}
      >
        <VectorIcon />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actionsVisibility.isRegister &&
          !appointment?.patient?.additional_info?.ethnicity?.length && (
          <MenuItem
            onClick={() =>
              navigate('/admin/registration/patient-partner-registration', {
                state: appointment?.patient,
              })
            }
          >
            <img
              src={registerUserIcon}
              width={22}
              style={{ marginRight: 5 }}
              alt="register-icon"
            />
              Register
          </MenuItem>
        )}
        {actionsVisibility.isCheckedIn && !appointment?.checked_in_time && (
          <MenuItem
            disabled={
              !appointment?.patient?.additional_info?.ethnicity?.length
            }
            onClick={() =>
              updateStatus(AppointmentStatus.CHECKED_IN, appointment?.id)
            }
          >
            <img
              src={checkInIcon}
              style={{ marginRight: 5 }}
              alt="checkIn-icon"
            />
            Check-In
            {!appointment?.patient?.additional_info?.ethnicity?.length && (
              <Typography color="warning" ml={2}>
                (Registration form must be completed before check-in)
              </Typography>
            )}
          </MenuItem>
        )}
        {actionsVisibility.isCheckOut && (
          <MenuItem
            onClick={() =>
              updateStatus(AppointmentStatus.CHECKED_OUT, appointment?.id)
            }
          >
            <img
              src={checkOutIcon}
              style={{ marginRight: 5 }}
              alt="checkOut-icon"
            />
            Check-Out
          </MenuItem>
        )}
        <MenuItem onClick={handleRescheduleView}>
          <img
            src={rescheduleIcon}
            width={22}
            style={{ marginRight: 5 }}
            alt="reschedule-icon"
          />
          Reschedule
        </MenuItem>
        <MenuItem onClick={handleCancelView}>
          <img
            src={cancelIcon}
            width={22}
            style={{ marginRight: 5 }}
            alt="cancel-user"
          />
          Cancel
        </MenuItem>
      </StyledMenu>

      { viewModal ? <CustomModal id={appSelectedId} isOpen={viewModal} onClose={() => setViewModalOpen(false)} /> : null}

      <Modal open={rescheduled || cancelView} onClose={handleClose}>
        <Box sx={actionModalStyle}>
      
          {/* Rescheduled / Cancel Heading */}
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <div>
                <img src={cancelAlertIcon} alt='cancel-icon' />
              </div>

              <div>
                <Typography fontFamily='Montserrat' fontWeight={600} fontSize='20px' color='#232323'>
                  {rescheduled ? 'Reschedule Appointment ?' : 'Cancel Appointment ?'}
                </Typography>
                <Typography fontFamily='Nunito' fontWeight={400} fontSize='14px' color='#404653'>
                  {rescheduled ? 'Are you sure you want to reschedule this appointment ?' : 'Are you sure you want to cancel this appointment ?'}
                </Typography>
              </div>
            </div>
            <img src={closeIcon} style={{ cursor: 'pointer' }} onClick={handleModalClose} width={28} alt='close-icon' />
          </Stack>


          {
            rescheduled ?
              <Rescheduled data={selectedApp} onClose={handleModalClose} /> : 
              cancelView ? 
                <Cancel data={selectedApp} onClose={handleModalClose} /> : 
                null
          }

        </Box>
      </Modal>
    </div>
  );
};

export default ActionsMenu;
