/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect ,useState,useCallback } from 'react';
import styles from './sidebarComponent.module.css';
import { ReactComponent as SelectedAdminTop } from '../../assets/SVG/sidebar/selectedAdminTop.svg';
import { ReactComponent as MedicalIcon } from '../../assets/SVG/sidebar/selectedClinicalTop.svg';
import { ReactComponent as SemenProcessingIcon } from '../../assets/SVG/sidebar/selectedLab.svg';
import { ReactComponent as AdminIcon } from '../../assets/SVG/Admin.svg';
import { ReactComponent as ClinicalIcon } from '../../assets/SVG/sidebar/selectedClinical.svg';
import { ReactComponent as LabIcon } from '../../assets/SVG/sidebar/Lab.svg';
import { ReactComponent as VidaiLogoIcon } from '../../assets/SVG/Vidai Updated Logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate,useLocation, useParams  } from 'react-router-dom';
import { getPatientName } from '../../services/apiService';
import { BasicPatientInfo } from '../../types';
import DefaultImageMale from '../../assets/SVG/Default Profile - Male.svg';
import DefaultImageFemale from '../../assets/SVG/Default Profile - Female.svg';
import ImageWithSkeleton from '../customImageComponent';
import { setPatientDetails, setPatientId } from '../../services/sharedService';
import exitPatient from '../../assets/SVG/exitPatient.svg';
import { usePatientContext } from '../patinetProvider';
import { usePatientContext as useSubPatientContext } from '../SubPatinetProvider';

const SideBarComponent = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [transformWidth, setTransformWidth] = useState(4);
  const [selectedMenu, setSelectedMenu] = useState('admin');
  const [selectedSubMenu, setSelectedSubMenu] = useState('admin');
  const [mainmenu, setMainMenu] = useState('admin');
  const [activeclinicalmenu, setActiveclinicalmenu] = useState('');
  const [activelabmenu, setActiveLabmenu] = useState('');
  const [selectedClinicalmenu, setselectedClinicalmenu] = useState('');
  const [selectedlabtaskmenu, setSelectedLabtaskmenu] = useState('');

  const [user, setUser] = useState<BasicPatientInfo | null>(null);
  const [userP, setUserP] = useState<BasicPatientInfo | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId, removePatient, addPatient } = usePatientContext();
  const { removeSubPatient, addSubPatient } = useSubPatientContext();

  const { id } = useParams<{ id: string}>();

  useEffect(() => {
    if (patientId > 0) {
      getPatientName(patientId.toString()).then((response: BasicPatientInfo) => {
        setUserP({
          first_name: response.first_name,
          last_name: response.last_name,
          photo_url: response.photo_url,
          gender: response.gender
        });
      });
    } else {
      setUserP(null);
    }
     
  }, [patientId]);

  const changeMenu = useCallback((menuName: string): void => {;
    setMainMenu(menuName);
    if (menuName === 'admin' && location.pathname !== '/admin/appointments' && patientId <= 0) {
      navigate('/admin/appointments');
    }
    else if (menuName === 'admin' && patientId > 0){
      navigate(`/admin/registration/patient/${patientId}`);
    }
  }, [location.pathname, navigate, patientId]);

  const activesubmenu = useCallback( (e: string): void => {
    setSelectedSubMenu(e);
  },[]);

  const activeMenu = useCallback((e: string): void => {

    if (e === selectedMenu) {
      if (e === 'Calendar') {
        setSelectedMenu('Calendar');
      }
    }
    else if(e === 'Stock'){
      activesubmenu('Stock');
    }
    setSelectedMenu(e);

  },[selectedMenu, activesubmenu]);


  const navToRegistration = () => {
    if (patientId > 0 ) {
      navigate(`/admin/registration/patient/${patientId}`);
    }else{
      navigate('/admin/registration/patient-list');
    }
  };

  const setActive = useCallback((index: number): void => {
    if (index === 1) {
      setTransformWidth(4);
    } else if (index === 2) {
      setTransformWidth(73);
      navigate('/clinical/patients');
    } else {
      setTransformWidth(140);
    }
    setActiveIndex(index);
  }, [navigate]); // Include dependencies

  useEffect(() => {
    if (location.pathname === '/') {
      changeMenu('admin');
    }
  }, [location.pathname, changeMenu]);

  const [pathArray, setPathArray] = useState([location.pathname]);

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter(Boolean);
    setPathArray(pathArray);

    if (id) {
      addPatient(Number(id));
      addSubPatient(Number(id));
    }

  }, [location.pathname,addPatient, id, addSubPatient]);

  useEffect(()=>{
    switch (pathArray[0]) {
    case 'admin':
      setMainMenu('admin');
      setActiveIndex(1);
      setTransformWidth(4);
      break;
    case 'clinical':
      setMainMenu('clinical');
      setActiveIndex(2);
      setTransformWidth(73);
      break;
    case 'lab':
      setMainMenu('lab');
      setActiveIndex(3);
      setTransformWidth(140);
      break;
    }

    if (location.pathname === '/clinical/patients' && patientId > 0) {
      switch (pathArray[2]) {
      case 'medical-history':
        navigate(`/clinical/patients/medical-history/${patientId}`);
        break;
      case 'hpi':
        navigate(`/clinical/patients/hpi/${patientId}`);
        break;
      case 'examination':
        navigate(`/clinical/patients/examination/${patientId}`);

        break;
      case 'investigation':
        navigate(`/clinical/patients/investigation/${patientId}`);

        break;
      case 'stimulation':
        navigate(`/clinical/patients/stimulation/${patientId}`);
  
        break;
      default:
        break;
      }
    }
    switch (pathArray[2]) {
    case 'medical-history':
      setActiveclinicalmenu('Clinical Profile');
      setselectedClinicalmenu('medical-history');
      break;
    case 'hpi':
      setActiveclinicalmenu('Clinical Profile');
      setselectedClinicalmenu('hpi');
      break;
    case 'examination':
      setActiveclinicalmenu('Clinical Profile');
      setselectedClinicalmenu('examination');
      break;
    case 'investigation':
      setActiveclinicalmenu('Clinical Profile');
      setselectedClinicalmenu('investigation');
      break;
    case 'stimulation':
      setActiveclinicalmenu('Clinical Profile');
      setselectedClinicalmenu('stimulation');
      break;
    default:
      break;
    }
    // eslint-disable-next-line
  },[pathArray]);
  useEffect(() => {

    if (location.pathname === '/admin/registration/patient-list') {
      activeMenu('Registration');
      activesubmenu('patient list');
    }
    else if (location.pathname === '/admin/registration/patient-partner-registration') {

      activesubmenu('Registration Details');
    }
    if (location.pathname === '/admin/appointments') {
      activeMenu('Calendar');
      activesubmenu('Consultation');
    }
    if (location.pathname === '/clinical/patients') {
      changeMenu('clinical');
      setActive(2);
      setActiveclinicalmenu('Clinical Profile');
    }

    if (location.pathname.includes('admin/inventory')){
      activeMenu('Stock');
    }
    if (location.pathname.includes('/admin/registration/patient') && location.pathname !== '/admin/registration/patient' && !sessionStorage.getItem('prevRoute')){
      sessionStorage.setItem('prevRoute','/admin/registration/patient-list');
    }
    if (location.pathname.includes('/clinical/patients')&& location.pathname !== '/clinical/patients' && !sessionStorage.getItem('prevRoute')){
      sessionStorage.setItem('prevRoute','/clinical/patients');
    }

    if (location.pathname.includes('/admin/registration/patient/') ) {

      const id = location.pathname.split('/').pop();
      if (id && Number(id) > 0) {
        getPatientName(id.toString()).then((response: BasicPatientInfo) => {
          setUser({
            first_name: response.first_name,
            last_name: response.last_name,
            photo_url: response.photo_url,
            gender: response.gender
          });
        });
      }
    } else {setUser(null);}
    // eslint-disable-next-line
  }, [location.pathname]);

  const transformStyle = {
    transform: `translateX(${transformWidth}px)`,
  };


  const handleSubMenuClick = () => {
    navigate('/admin/Registration/patient-list');
  };




  const activeclinicaltask = (e: string): void => {

    setActiveclinicalmenu(e);

  };

  const activelabtask = (e: string): void => {
    if (e === activelabmenu) {
      setActiveLabmenu('');
    } else {
      setActiveLabmenu(e);
    }
  };

  const selectedClinicaltask = (e: string): void => {
    setselectedClinicalmenu(e);
  };

  const activelasbtask = (e: string): void => {
    setSelectedLabtaskmenu(e);
  };
  const exitPatientProfile = ():void =>{
    sessionStorage.removeItem('patientId');
    navigate(`${sessionStorage.getItem('prevRoute')}`);
    removePatient();
    removeSubPatient();
    setPatientDetails(null);
    setUserP(null);
    sessionStorage.removeItem('prevRoute');
  };
  function getUserNameAndProfilePic(): React.ReactNode {
    return (
      user && (
        <div className='d-flex'>
          <div>
            <img src={exitPatient} alt="exitPatient" width={'25px'} />
          </div>
          <div className={styles.userCard}>
            <ImageWithSkeleton src={user.photo_url} alt={'pc'} fallbackSrc={user.gender === 'FEMALE'? DefaultImageFemale: DefaultImageMale} height={'2rem'} width={'2rem'} borderRadius={'50%'}/>
            <label>Patient List</label>
          </div>
        </div>
      )
    );
  }

  function handleDPClick(): any {
    if (patientId) {
      addPatient(patientId);
      return new Promise<void>((resolve) => {
        setPatientId(patientId.toString());
        resolve();
      })
        .then(() => {
          navigate(location.pathname + location.search, { replace: true });
        });
    }
  }

  return (
    <div className={styles.contained}>
      <div className={styles.navigation}>
        <ul className='p-0 w-100'>
          <li
            className={`list ${activeIndex === 1 ? styles.active : ''}`}
            onClick={() => {
              setActive(1);
              changeMenu('admin');
            }}
          >
            <span>
              <span className={`icon ${activeIndex === 1 ? styles.clicked : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {activeIndex === 1 ? <SelectedAdminTop className={styles.svgclass} /> : <AdminIcon  className={styles.svgclass}/>}
                <span style={{
                  fontSize: '12px',
                  color: activeIndex === 1 ? '#e17e61' : '#8F8888', // Dynamic color
                  fontWeight: activeIndex === 1 ? 600:500
                }}>Admin</span>

              </span>
            </span>
          </li>
          <li
            className={`list ${activeIndex === 2 ? styles.active : ''}`}
            onClick={() => {
              setActive(2);
              changeMenu('clinical');
            }}
          >
            <span>
              <span className={`icon ${activeIndex === 2 ? styles.clicked : ''}`}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {activeIndex === 2 ? <MedicalIcon className={styles.svgclass} /> : <ClinicalIcon className={styles.svgclass} />}
                <span style={{
                  fontSize: '12px',
                  color: activeIndex === 2 ? '#e17e61' : '#8F8888', // Dynamic color
                  fontWeight: activeIndex === 2 ? 600:500,
                }}>Clinical</span>
              </span>
            </span>
          </li>
          <li
            className={`list ${activeIndex === 3 ? styles.active : ''}`}
            onClick={() => {
              setActive(3);
              changeMenu('lab');
            }}
          >
            <span>
              <span className={`icon ${activeIndex === 3 ? styles.clicked : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {activeIndex === 3 ? <SemenProcessingIcon className={styles.svgclass} /> : <LabIcon className={styles.svgclass} />}
                <span style={{
                  fontSize: '12px',
                  color: activeIndex === 3 ? '#e17e61' : '#8F8888', // Dynamic color
                  fontWeight: activeIndex === 3 ? 600:500,
                }}>Lab</span>

              </span>
            </span>
          </li>
          <svg
            style={{ fill: 'rgb(248, 247, 247)', ...transformStyle }}
            className={styles.indicator}
            width='94'
            height='56'
          >
            <ellipse cx='47' cy='30' rx='24' ry='30' />
            <path d='M24 20C24 20 28 55.9999 48 56L0 55.9997C18 55.9998 24 20 24 20Z' />
            <path d='M70 20C70 20 66 55.9999 46 56L94 55.9997C76 55.9998 70 20 70 20Z' />
          </svg>
        </ul>
      </div>
      {mainmenu === 'admin' && (
        <div className={`${styles.navigationsidebar}`}>
          <VidaiLogoIcon
            style={{
              position: 'absolute',
              bottom: '8%',
              width: '60%',
              left: ' 50px',
            }}
          >

          </VidaiLogoIcon>
          <h6 className={`${styles.versiontext}`}>Updated Version: 2.0</h6>
          <div
            className={`${styles.adminmenu} row`}
            style={{ '--bs-gutter-x': '0' } as React.CSSProperties}
          >
            {/* <h6
              className={`${styles.headeradmin} font-24 text-danger py-3 mb-0 bg-white px-0`}
            >
              <SelectedAdminIcon
                className={`${styles.imgadminicon}`}
              ></SelectedAdminIcon>
              <span className={styles.anchoradmin}>Admin</span>
            </h6> */}
            <ul
              className={`${styles.sidebarunorderedList} text-muted bg-white px-0 pb-5`}
            >
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${selectedMenu === 'Calendar'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => {activeMenu('Calendar'); navigate('/admin/appointments');}}
                >
                  Calendar
                </li>
              </div>
              <div className={`${styles.orderlistDiv}`}>
                {user ? (
                // Show gender profile `li` if user exists
                  <li
                    className={`${selectedMenu === 'GenderProfile'
                      ? styles.boldClass
                      : styles.orderlistLi
                    }`}
                    onClick={() => {
                      activeMenu('GenderProfile');
                      exitPatientProfile();
                    }}
                  >
                    {getUserNameAndProfilePic()}
                  </li>
                ) : (
                // Show registration `li` if user does not exist
                  <li
                    className={`${selectedMenu === 'Registration'
                      ? styles.boldClass
                      : styles.orderlistLi
                    }`}
                    onClick={() => {
                      activeMenu('Registration');
                      navToRegistration();
                    }}
                  >
    Registration
                  </li>
                )}
              </div>
              {selectedMenu === 'Registration' && location.pathname === '/admin/registration/patient-partner-registration' && (
                <div className={styles.mainclass}>
                  <ul className={`${styles.subunorderList}`}>
                    <li
                      className={`${selectedSubMenu === 'Registration Details'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Registration Details')}
                    >
                      <label onClick={handleSubMenuClick}>Registration Details</label>
                    </li>

                  </ul>
                </div>
              )}
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${selectedMenu === 'Billing'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => activeMenu('Billing')}
                >
                  Billing
                </li>
              </div>
              {selectedMenu === 'Billing' && (
                <div className={styles.mainclass}>
                  <ul className={`${styles.subunorderList}`}>
                    <li
                      className={`${selectedSubMenu === 'Eligibility'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Eligibility')}
                    >
                      Eligibility
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Package Details'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Package Details')}
                    >
                      Package Details
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Authorization'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Authorization')}
                    >
                      Authorization
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Generate Invoice'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Generate Invoice')}
                    >
                      Generate Invoice
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Claim Submission'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Claim Submission')}
                    >
                      Claim Submission
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Collect Advance'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Collect Advance')}
                    >
                      Collect Advance
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Refund'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Refund')}
                    >
                      Refund
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Pharmacy'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Pharmacy')}
                    >
                      Pharmacy
                    </li>
                  </ul>
                </div>
              )}
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${selectedMenu === 'Stock'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => {activeMenu('Stock'); navigate('/admin/inventory/stock');}}
                >
                  Inventory
                </li>
              </div>
              {selectedMenu === 'Stock' && (
                <div className={styles.mainclass}>
                  <ul className={`${styles.subunorderList}`}>
                    <li
                      className={`${selectedSubMenu === 'Stock'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => {activesubmenu('Stock');navigate('/admin/inventory/stock');}}
                    >
                      Stock
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Stock Transfer'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => {activesubmenu('Stock Transfer');navigate('/admin/inventory/stock-transfer');}}
                    >
                      Stock Transfer
                    </li>
                    <li
                      className={`${selectedSubMenu === 'GRN'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('GRN')}
                    >
                      GRN
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Purchase'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Purchase')}
                    >
                      Purchase
                    </li>
                    <li
                      className={`${selectedSubMenu === 'Pharmacy'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activesubmenu('Pharmacy')}
                    >
                      Pharmacy
                    </li>
                  </ul>
                </div>
              )}


            </ul>
          </div>
        </div>
      )}

      {mainmenu === 'clinical' && (
        <div className={styles.navigationsidebar}>
          <VidaiLogoIcon
            style={{
              position: 'absolute',
              bottom: '8%',
              width: '60%',
              left: ' 50px',
            }}
          >

          </VidaiLogoIcon>
          <h6 className={`${styles.versiontext}`}>Updated Version: 2.0</h6>

          <div
            className={`${styles.adminmenu} row`}
            style={{ '--bs-gutter-x': '0' } as React.CSSProperties}
          >
            {/* <h6
              className={`${styles.headeradmin} font-24 text-danger py-3 mb-0 bg-white px-0`}
            >
              <MedicalIcon className={`${styles.imgadminicon}`}> </MedicalIcon>
              <span className={styles.anchoradmin}>Clinical</span>
            </h6> */}
            <div>
              <ul
                className={`${styles.sidebarunorderedList} text-muted bg-white p-0 `}
                style={{borderRadius:'30px'}}
              >

                <div  style={
                  userP
                    ? {
                      background: 'linear-gradient(199.55deg, #EEEEEE 20.06%, rgba(243, 243, 243, 0.547773) 75.62%, rgba(232, 234, 236, 0) 110.17%)' ,
                      borderRadius: '30px'
                    }
                    : {
                      borderRadius: '30px'
                    }

                }>
                  <div className={`${styles.orderlistDiv} py-3`} style={{borderRadius:'20px 20px 20px 0px'}}
                  >
                    <li
                      className={`${activeclinicalmenu === 'Clinical Profile' 
                        ? (userP ? styles.boldClass1 : styles.boldClass) 
                        : styles.orderlistLi}`}

                    >
                      {userP ? (
                        <div className='d-flex' style={{alignItems:'center'}}>
                          <div  onClick={() => exitPatientProfile()}>
                            <img src={exitPatient} alt="exitPatient" width={'25px'} />
                          </div>

                          <div className={styles.userCard} onClick={handleDPClick}>
                            <ImageWithSkeleton
                              src={userP.photo_url}
                              alt={'pc'}
                              fallbackSrc={userP.gender === 'FEMALE' ? DefaultImageFemale : DefaultImageMale}
                              height={'2rem'}
                              width={'2rem'}
                              borderRadius={'50%'}
                            />
                          </div>

                          <div style={{ paddingLeft:'0.5rem'}} onClick={() => exitPatientProfile()}>
                            <p>Patient Profile</p>
                          </div>

                        </div>
                      ) : (
                        <div className='d-flex' onClick={() => {activeclinicaltask('Clinical Profile');}}>
                          <div>
                            <p>Patient List</p>
                          </div>
                        </div>
                      )}
                      {/* Clinical Profile */}
                    </li>
                  </div>
                  {activeclinicalmenu === 'Clinical Profile'&& patientId > 0 && (
                    <div style={{backgroundColor:'#FFF'}}>
                      <ul className={`${styles.subunorderList}`}>
                        {/* <li
                      className={`${selectedClinicalmenu === 'Summary'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Summary')}
                    >
                      Summary
                    </li> */}
                        <li
                          className={`${selectedClinicalmenu === 'medical-history'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('medical-history') ;navigate(`/clinical/patients/medical-history/${patientId}`);}}
                        >
                      Medical History
                        </li>
                        <li
                          className={`${selectedClinicalmenu === 'hpi'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('hpi'); navigate(`/clinical/patients/hpi/${patientId}`);}}
                        >
                      HPI
                        </li>
                        <li
                          className={`${selectedClinicalmenu === 'examination'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('examination');navigate(`/clinical/patients/examination/${patientId}`);}}
                        >
                      Examination
                        </li>
                        <li
                          className={`${selectedClinicalmenu === 'investigation'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('investigation'); navigate(`/clinical/patients/investigation/${patientId}`);}}
                        >
                      Investigation
                        </li>

                        <li
                          className={`${selectedClinicalmenu === 'cycle-overview'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('cycle-overview'); navigate(`/clinical/patients/cycle-overview/${patientId}`);}}
                        >
                     Cycle Overview
                        </li>


                        <li
                          className={`${selectedClinicalmenu === 'Stimulation'
                            ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                            : styles.subunorderedlistLi
                          }`}
                          onClick={() => {selectedClinicaltask('Stimulation');navigate(`/clinical/patients/stimulation/${patientId}`);}}
                        >
                      Stimulation
                        </li>
                        {/*<li
                      className={`${selectedClinicalmenu === 'Diagnosis'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Diagnosis')}
                    >
                      Diagnosis
                    </li>
                    <li
                      className={`${selectedClinicalmenu === 'Treatment Plan'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Treatment Plan')}
                    >
                      Treatment Plan
                    </li>
                    <li
                      className={`${selectedClinicalmenu === 'Cycle Overview'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Cycle Overview')}
                    >
                      Cycle Overview
                    </li>
                    
                    <li
                      className={`${selectedClinicalmenu === 'Surgery'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Surgery')}
                    >
                      Surgery
                    </li>
                    <li
                      className={`${selectedClinicalmenu === 'Outcome'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Outcome')}
                    >
                      Outcome
                    </li>
                    <li
                      className={`${selectedClinicalmenu === 'Documents'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => selectedClinicaltask('Documents')}
                    >
                      Documents
                    </li> */}

                      </ul>
                    </div>
                  )}
                </div>

                {activeclinicalmenu === 'Treatment' && (
                  <div>
                    <ul className={`${styles.subunorderList}`}>
                      <li
                        className={`${selectedClinicalmenu === 'Treatment Plan'
                          ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                          : styles.subunorderedlistLi
                        }`}
                        onClick={() => selectedClinicaltask('Treatment Plan')}
                      >
                      Treatment Plan
                      </li>
                      <li
                        className={`${selectedClinicalmenu === 'ART Cycle'
                          ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                          : styles.subunorderedlistLi
                        }`}
                        onClick={() => selectedClinicaltask('ART Cycle')}
                      >
                      ART Cycle
                      </li>
                      <li
                        className={`${selectedClinicalmenu === 'Stimulation'
                          ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                          : styles.subunorderedlistLi
                        }`}
                        onClick={() => selectedClinicaltask('Stimulation')}
                      >
                      Stimulation
                      </li>
                      <li
                        className={`${selectedClinicalmenu === 'Surgery'
                          ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                          : styles.subunorderedlistLi
                        }`}
                        onClick={() => selectedClinicaltask('Surgery')}
                      >
                      Surgery
                      </li>
                      <li
                        className={`${selectedClinicalmenu === 'Outcome'
                          ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                          : styles.subunorderedlistLi
                        }`}
                        onClick={() => selectedClinicaltask('Outcome')}
                      >
                      Outcome
                      </li>
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}

      {mainmenu === 'lab' && (
        <div className={styles.navigationsidebar}>
          <VidaiLogoIcon
            style={{
              position: 'absolute',
              bottom: '8%',
              width: '60%',
              left: '40px',
            }}
          >

          </VidaiLogoIcon>
          <h6 className={`${styles.versiontext}`}>Updated Version: 2.0</h6>

          <div
            className={`${styles.adminmenu} row`}
            style={{ '--bs-gutter-x': '0' } as React.CSSProperties}
          >
            {/* <h6
              className={`${styles.headeradmin} font-24 text-danger py-3 mb-0 bg-white px-0`}
            >
              <SemenProcessingIcon className={`${styles.imgadminicon}`}>
                {' '}
              </SemenProcessingIcon>
              <span className={styles.anchoradmin}>Lab</span>
            </h6> */}
            <ul
              className={`${styles.sidebarunorderedList} text-muted bg-white px-0 pb-5`}
            >
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${activelabmenu === 'Embrology'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => activelabtask('Embrology')}
                >
                  Embrology
                </li>
              </div>
              {activelabmenu === 'Embrology' && (
                <div>
                  <ul className={`${styles.subunorderList}`}>
                    <li
                      className={`${selectedlabtaskmenu === 'Treatment Plan'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('Treatment Plan')}
                    >
                      Treatment Plan
                    </li>
                    <li
                      className={`${selectedlabtaskmenu === 'ART Cycle'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('ART Cycle')}
                    >
                      ART Cycle
                    </li>
                    <li
                      className={`${selectedlabtaskmenu === 'Stimulation'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('Stimulation')}
                    >
                      Stimulation
                    </li>
                  </ul>
                </div>
              )}
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${activelabmenu === 'Andrology'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => activelabtask('Andrology')}
                >
                  Andrology
                </li>
              </div>
              {activelabmenu === 'Andrology' && (
                <div>
                  <ul className={`${styles.subunorderList}`}>
                    <li
                      className={`${selectedlabtaskmenu === 'Semen Analysis'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('Semen Analysis')}
                    >
                      Semen Analysis
                    </li>
                    <li
                      className={`${selectedlabtaskmenu === 'Semen Processing'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('Semen Processing')}
                    >
                      Semen Processing
                    </li>
                    <li
                      className={`${selectedlabtaskmenu === 'Semen Thawing'
                        ? `${styles.subunorderedlistLiChecked} ${styles.active}`
                        : styles.subunorderedlistLi
                      }`}
                      onClick={() => activelasbtask('Semen Thawing')}
                    >
                      Semen Thawing
                    </li>
                  </ul>
                </div>
              )}
              <div className={`${styles.orderlistDiv}`}>
                <li
                  className={`${activelabmenu === 'Cryostorage'
                    ? styles.boldClass
                    : styles.orderlistLi
                  }`}
                  onClick={() => activelabtask('Cryostorage')}
                >
                  Cryostorage
                </li>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBarComponent;