import React, { useState, useEffect, CSSProperties } from 'react';
import { Skeleton } from '@mui/material';

interface ImageWithSkeletonProps {
  src?: string | null;
  alt?: string;
  fallbackSrc?: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  onClick?: () => void;
  containerStyle?: CSSProperties; // Custom styles for container
  maxLoadTime?: number; // Max time in milliseconds to wait for image to load
  hideOverflow?: boolean; // Control overflow behavior
}

const ImageWithSkeleton: React.FC<ImageWithSkeletonProps> = ({
  src,
  alt,
  fallbackSrc,
  width = '100%',
  height = 200,
  borderRadius = 0,
  onClick,
  containerStyle,
  maxLoadTime = 5000, // Default to 5 seconds
  hideOverflow = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState<string | undefined>(src || fallbackSrc);

  // Track previous src and fallbackSrc to detect changes
  const prevSrc = React.useRef(src);
  const prevFallbackSrc = React.useRef(fallbackSrc);

  useEffect(() => {
    // Detect changes in src or fallbackSrc
    if (src !== prevSrc.current || fallbackSrc !== prevFallbackSrc.current) {
      setImgSrc(src || fallbackSrc);
      setIsLoading(true);
      prevSrc.current = src;
      prevFallbackSrc.current = fallbackSrc;
    }

    // Set a timeout to switch to fallback if maxLoadTime is exceeded
    const timer = setTimeout(() => {
      if (isLoading && fallbackSrc) {
        setImgSrc(fallbackSrc);
        setIsLoading(false);
      }
    }, maxLoadTime);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, [src, fallbackSrc, maxLoadTime, isLoading]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    if (fallbackSrc && imgSrc !== fallbackSrc) {
      setImgSrc(fallbackSrc);
    } else {
      setIsLoading(false); // Stop showing skeleton if no fallback
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        borderRadius,
        overflow: hideOverflow ? 'hidden' : 'visible',
        cursor: onClick ? 'pointer' : 'default',
        ...containerStyle,
      }}
      onClick={onClick}
    >
      {isLoading && (
        <Skeleton
          variant={typeof width === 'number' && typeof height === 'number' && width === height ? 'circular' : 'rectangular'}
          width={width}
          height={height}
          style={{ borderRadius }}
        />
      )}
      <img
        src={imgSrc}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{
          display: isLoading ? 'none' : 'block',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius,
          cursor: onClick ? 'pointer' : 'default',
        }}
      />
    </div>
  );
};

export default ImageWithSkeleton;