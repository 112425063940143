import { getRequest } from '../../services/httpService';




export interface CycleDetailsType {
  id: number;
  cycle_name: string;
  procedure: {
    id: number;
    services: number[];
    procedures: string;
    procedures_type: string;
    procedure_notes: string | null;
    created_on: string;
    donor_info: {
      surrogate: string | null;
      sperm_source: number[];
      oocyte_source: number[];
    } | null;
    indication: string;
  };
  medication_protocol: number;
  cycle_provider: number;
  provider_name: string;
  cycle_coordinator: number;
  coordinator_name: string;
  cycle_creator: number;
  cycle_start_date: string;
  cycle_end_date: string;
  lmp_date: string;
  remarks: string | null;
  status: string;
  patient: number;
  patient_name: string;
  cycle_number: string;
  stimulation_chart: number | null;
}

export function getcycleListUrl(Id: string): string {
  return `/cycle/${Id}/`; 
}

export function getcycleList(Id: string): Promise<CycleDetailsType> {
  return getRequest(getcycleListUrl(Id), { responseType: 'json' });
}




